import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MonitoringOverview,
  useSriracha,
  PremiumDataModal,
  usePremiumDataModal,
  useAccount,
} from '@ltvco/refresh-lib/v1';
import { constants } from 'appConstants';
import { checkPlanVariations } from 'utils/checkPlanVariations';
import { PremiumPaymentSuccessModal } from '@ltvco/refresh-lib/payments';

export function Monitoring() {
  const { data } = useSriracha();
  const notifications = data || [];
  const navigate = useNavigate();

  const { data: account } = useAccount(true);
  const { isModalOpen, closeModal, triggerModal } = usePremiumDataModal();
  const [searchType, setSearchType] = useState('');
  const [isInSingleReportVariation, setIsInSingleReportVariation] =
    useState(false);
  const [paymentShowModalNumber, setPaymentShowModalNumber] =
    useState<number>(0);
  const [isSuccessModaVisible, setIsSuccessModaVisible] =
    useState<boolean>(false);

  const openSuccessModal = () => {
    setIsSuccessModaVisible(true);
  };

  const closeModals = () => {
    setIsSuccessModaVisible(false);
    setPaymentShowModalNumber(0);
  };

  const onSuccessPaymentHandler = () => {
    closeModals();
    navigate('/dashboard');
  };

  const onClickHandler = () => {
    if (isInSingleReportVariation) {
      triggerModal(true, isInSingleReportVariation);
    }
  };

  useEffect(() => {
    if (!account) return;
    const planName =
      account.account.subscription_info.subscription_plan_unique_name;
    const { isInSingleReportVariation } = checkPlanVariations(planName);
    setIsInSingleReportVariation(isInSingleReportVariation);
  }, [account]);

  return (
    <>
      <MonitoringOverview
        notifications={notifications}
        onClickHandler={onClickHandler}
      />
      {isInSingleReportVariation && (
        <PremiumDataModal
          isOpen={isModalOpen}
          onClose={closeModal}
          searchType={searchType}
          paymentShowModalNumber={paymentShowModalNumber}
          setPaymentShowModalNumber={setPaymentShowModalNumber}
          openSuccessModal={openSuccessModal}
          shouldFireGAUpsell
        />
      )}
      <PremiumPaymentSuccessModal
        open={isSuccessModaVisible}
        onClose={closeModals}
        onAccept={onSuccessPaymentHandler}
      />
    </>
  );
}
