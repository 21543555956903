import {
  forwardRef,
  ForwardedRef,
  useEffect,
  useState,
  useContext,
} from 'react';
import { Alert } from '@ltvco/refresh-lib/theme';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { LoginForm, LoginFormProps, useCaptcha } from '@ltvco/refresh-lib/v1';
import { Stack, Text, Link as MuiLink, styled } from '@ltvco/refresh-lib/theme';
import { Outlet, Link } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';

const StyledText = styled(Text)(({ theme }) => ({
  letterSpacing: 0.5,
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.success.main,
  textDecoration: 'underline',
}));

const Container = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(0, 'auto'),
  overflow: 'visible !important',
  position: 'relative',
  padding: theme.spacing('19vh', 0),
}));

interface Props {
  onLoginSuccess: LoginFormProps['onLoginSuccess'];
}

const VERIFICATION_EVENT_ACTION = 'view';
const VERIFICATION_EVENT_LABEL = 'email_verification_response';

export const Login = forwardRef(
  ({ onLoginSuccess }: Props, ref: ForwardedRef<null>) => {
    useEffect(() => {
      document.title = 'Login - NeighborWho';
    }, []);

    const [statusParam, setStatusParam] = useQueryParam('status', StringParam);
    const [emailVerificationAlert, setEmailVerificationAlert] = useState<
      JSX.Element | undefined
    >(undefined);
    const { trackEvent } = useContext(AppConfig);
    const [captcha, captchaReady, sitekey, renderCaptcha] = useCaptcha();

    useEffect(() => {
      let alertContent;

      if (statusParam === 'success') {
        alertContent = (
          <Alert variant="outlined" severity="success">
            Your email is successfully verified! Please sign in.
          </Alert>
        );
        trackEvent(
          'success_link',
          VERIFICATION_EVENT_ACTION,
          VERIFICATION_EVENT_LABEL
        );
      } else if (statusParam === 'expired') {
        alertContent = (
          <Alert
            variant="outlined"
            sx={{ color: 'error.main' }}
            severity="error"
          >
            Sorry, the verification link you used is expired. We sent a new link
            to your inbox, please try again.
          </Alert>
        );
        trackEvent(
          'expired_link',
          VERIFICATION_EVENT_ACTION,
          VERIFICATION_EVENT_LABEL
        );
      } else if (statusParam === 'invalid') {
        alertContent = (
          <Alert
            variant="outlined"
            sx={{ color: 'error.main' }}
            severity="error"
          >
            Sorry, the verification link you used is invalid. Please sign in.
          </Alert>
        );
        trackEvent(
          'invalid_link',
          VERIFICATION_EVENT_ACTION,
          VERIFICATION_EVENT_LABEL
        );
      }

      if (statusParam) {
        setEmailVerificationAlert(alertContent);
        setStatusParam(undefined, 'replaceIn');
      }
    }, [statusParam, setStatusParam]);

    return (
      <Container ref={ref} spacing={1.5} width={{ xs: '90%', md: '35%' }}>
        <Text
          variant="h2"
          fontSize="2.25rem"
          fontWeight={300}
          sx={{ margin: 0, fontWeight: '700' }}
          textAlign={'center'}
        >
          Sign In
        </Text>
        {emailVerificationAlert}
        <LoginForm
          captchaEnabled={!!captcha}
          captcha={captcha}
          captchaReady={captchaReady}
          sitekey={sitekey}
          renderCaptcha={renderCaptcha}
          onLoginSuccess={onLoginSuccess}
        />
        <Stack spacing={1.5} alignItems="center">
          <Link
            to={`/login/reset-password-request`}
            data-cy="forgot_password_link"
          >
            <StyledText variant="h5" lineHeight={1}>
              Forgot your password?
            </StyledText>
          </Link>
          <Link to={`/login/magic-link`} data-cy="magic_link_login">
            <StyledText variant="h5" lineHeight={1}>
              Send a One Time Login Link
            </StyledText>
          </Link>
          <Link to={`/login/forgot-email`} data-cy="forgot_email_link">
            <StyledText variant="h5" lineHeight={1}>
              Unsure what email you used?
            </StyledText>
          </Link>
        </Stack>
        <Outlet />
        {captcha === 'cf-turnstile' ? (
          <Text variant="caption" textAlign="center">
            This site is protected by{' '}
            <MuiLink
              href="https://www.cloudflare.com/products/turnstile/"
              target="_blank"
              rel="noopener noreferrer"
              color="success.main"
              underline="always"
            >
              Cloudflare Turnstile
            </MuiLink>{' '}
            and its{' '}
            <MuiLink
              href="https://www.cloudflare.com/privacypolicy/"
              target="_blank"
              rel="noopener noreferrer"
              color="success.main"
              underline="always"
            >
              Privacy Policy
            </MuiLink>{' '}
            and{' '}
            <MuiLink
              href="https://www.cloudflare.com/website-terms/"
              target="_blank"
              rel="noopener noreferrer"
              color="success.main"
              underline="always"
            >
              Terms of Service
            </MuiLink>{' '}
            apply.
          </Text>
        ) : captcha === 'hcaptcha' ? (
          <Text variant="caption" textAlign="center">
            This site is protected by{' '}
            <MuiLink
              href="https://www.hCaptcha.com"
              target="_blank"
              rel="noopener noreferrer"
              color="success.main"
              underline="always"
            >
              hCaptcha
            </MuiLink>{' '}
            and its{' '}
            <MuiLink
              href="https://www.hcaptcha.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              color="success.main"
              underline="always"
            >
              Privacy Policy
            </MuiLink>{' '}
            and{' '}
            <MuiLink
              href="https://www.hcaptcha.com/terms"
              target="_blank"
              rel="noopener noreferrer"
              color="success.main"
              underline="always"
            >
              Terms of Service
            </MuiLink>{' '}
            apply.
          </Text>
        ) : null}
      </Container>
    );
  }
);
