import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import {
  useReportMonitors,
  ClaimedSingleReportContainer,
  ClaimedReportsContainer,
  CTACard,
  MonitoringHeader,
  MainHeader,
  PersonReportCTA,
} from '@ltvco/refresh-lib/v1';
import { Grid, CustomIcons, useMediaQuery } from '@ltvco/refresh-lib/theme';
import { PersonRounded, Email, PhoneAndroid } from '@mui/icons-material';
import { useMemo } from 'react';
import { filterAndSortReports } from './utils';
import { IdentityHubShieldWithLogo } from 'utils/customIcons';
import { theme } from 'theme';

export function IdentityHub() {
  document.title = 'Identity Hub - NeighborWho';

  const { currentMonitors } = useReportMonitors();
  const { trackEvent } = useContext(AppConfig);
  const navigate = useNavigate();

  const filteredAndSortedReports = useMemo(
    () => filterAndSortReports(currentMonitors || []),
    [currentMonitors]
  );

  const claimedPersonReport =
    filteredAndSortedReports['detailed_person_report'];
  const claimedPhoneReports = filteredAndSortedReports['reverse_phone_report'];
  const claimedEmailReports = filteredAndSortedReports['social_network_report'];

  // add to this list as more report types get built out for this page
  const completed =
    !!claimedPhoneReports?.length &&
    !!claimedEmailReports?.length &&
    !!claimedPersonReport?.length;

  const largeScreens = useMediaQuery('(min-width: 1235px)');
  const midSizedScreen = useMediaQuery('(min-width: 900px)');
  const smallScreens = useMediaQuery('(max-width: 900px)');

  const adjustCardWidths = () => {
    if (smallScreens) return { width: '100%' };
    if (midSizedScreen && !largeScreens) return { minWidth: '400px' };
    if (largeScreens) return { minWidth: '50%' };
  };

  const darkWebNavigation = () => {
    trackEvent('dark web', 'navigation click', 'identity hub');
    navigate('/dashboard/dark-web');
  };

  return (
    <>
      <MainHeader
        icon={
          <IdentityHubShieldWithLogo
            sx={(theme) => ({
              fontSize: '2.8rem',
              marginRight: theme.spacing(1),
            })}
          />
        }
        brandName="NeighborWho"
      />
      <Grid container justifyContent={'space-around'} spacing={3}>
        <Grid item md={6} sx={adjustCardWidths()}>
          <CTACard
            onClick={darkWebNavigation}
            body="See if your information may have been leaked in a data breach"
            image={<CustomIcons.CreditCardIcon sx={{ fontSize: '3.125rem' }} />}
            title="Dark web scan"
            buttonLabel="Scan Email"
          />
        </Grid>
      </Grid>
      <MonitoringHeader completed={completed} />
      {claimedPersonReport ? (
        <ClaimedSingleReportContainer
          sectionTitle={'My Person Report'}
          sectionIcon={
            <PersonRounded sx={{ color: theme.palette.primary.main }} />
          }
          report={claimedPersonReport[0]}
          reportType="detailed_person_report"
        />
      ) : (
        <PersonReportCTA
          icon={<CustomIcons.SelfSearchBV sx={{ fontSize: '3.125rem' }} />}
        />
      )}
      {claimedEmailReports && <hr color="lightgray" />}
      <ClaimedReportsContainer
        sectionIcon={<Email sx={{ color: theme.palette.primary.main }} />}
        sectionTitle="My Emails"
        reports={claimedEmailReports || []}
        addAnotherButtonText="email"
        inputPlaceholder="EX: johnsmith@gmail.com"
        reportType="social_network_report"
      />
      {claimedPhoneReports && <hr color="lightgray" />}
      <ClaimedReportsContainer
        sectionIcon={
          <PhoneAndroid sx={{ color: theme.palette.primary.main }} />
        }
        sectionTitle="My Phone Numbers"
        reports={claimedPhoneReports || []}
        addAnotherButtonText="phone number"
        inputPlaceholder="EX: (123) 456-7890"
        reportType="reverse_phone_report"
      />
    </>
  );
}
