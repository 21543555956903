import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { Box, Divider, Tab, Tabs, useTheme } from '@ltvco/refresh-lib/theme';
import { SearchData, SearchTabData } from '@ltvco/refresh-lib/v1';
import {
  MobileTabsToggle,
  SearchFormsContainer,
  useSearchFormContext,
} from '@ltvco/refresh-lib/v2';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type SearchHubProps = {
  searchFrom?: string;
  isInSingleReportVariation?: boolean;
  openUpgradeModal?: (
    isItemLocked: boolean | undefined,
    isInSingleReportVariation: boolean
  ) => void;
};

export const SearchHub = ({
  searchFrom,
  isInSingleReportVariation,
  openUpgradeModal,
}: SearchHubProps) => {
  const { useSearchForm } = useSearchFormContext();
  const theme = useTheme();
  const {
    handleOpenFormContainer,
    searchTabs,
    activeFormTab,
    handleTabChange,
    setActiveFormTab,
  } = useSearchForm(true);

  const navigate = useNavigate();
  const handleSearchSuccess = ({
    reportType,
    searchParams,
    permalink,
  }: SearchData) => {
    const url = `/report/${reportType}?${searchParams}&permalink=${permalink}`;
    navigate(url);
  };
  const { isTablet } = useScreenSize();
  const handleTabClick = (tab: SearchTabData) => {
    const tabId = tab.id;
    if (isInSingleReportVariation && tab.isLocked) {
      openUpgradeModal && openUpgradeModal(true, isInSingleReportVariation);
      setActiveFormTab(searchTabs[0].id);
    } else {
      setActiveFormTab(tabId);
    }
  };
  return (
    <Box
      bgcolor="background.default"
      borderRadius={2}
      border="1px solid"
      borderColor="divider"
    >
      {!isTablet && (
        <>
          <Tabs
            value={activeFormTab}
            onChange={handleTabChange}
            TabIndicatorProps={{
              sx: {
                top: 60,
                backgroundColor: theme.palette.text.primary,
              },
            }}
            centered
          >
            {searchTabs.map((tab) => (
              <Tab
                disableRipple
                label={tab.displayName}
                key={tab.id}
                value={tab.id}
                icon={React.cloneElement(tab.icon, {
                  color: theme.palette.text.primary,
                })}
                iconPosition="start"
                onClick={() => handleTabClick(tab)}
                sx={{
                  color: theme.palette.text.primary,
                  '&.Mui-selected': { color: theme.palette.text.primary },
                }}
              />
            ))}
          </Tabs>
          <Divider sx={{ position: 'relative', top: -10 }} />
        </>
      )}
      {isTablet && (
        <Box bgcolor="background.paper">
          <MobileTabsToggle
            activeTab={activeFormTab}
            tabs={searchTabs}
            handleTabChange={handleTabChange}
          />
        </Box>
      )}
      <SearchFormsContainer
        activeTab={activeFormTab}
        searchTabs={searchTabs}
        onSearchSuccess={handleSearchSuccess}
        closeForm={handleOpenFormContainer}
        searchFrom={searchFrom}
      />
    </Box>
  );
};
