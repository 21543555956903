import {
  Box,
  Carousel,
  styled,
  SwiperNavigation,
} from '@ltvco/refresh-lib/theme';
import { MemberPerkContainer } from './components';
import { Reward, ReportSection } from '@ltvco/refresh-lib/v1';
import { useNavigate } from 'react-router-dom';

type MemberPerksProps = {
  rewards: Reward[];
};

const CarouselWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  '.carousel-div-wrapper': {
    width: '100%',
  },
}));

export const MemberPerks = ({ rewards }: MemberPerksProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/dashboard/rewards');
  };
  const elements = rewards.map((rewardItem) => (
    <MemberPerkContainer
      key={rewardItem.name}
      reward={rewardItem}
      cardClick={handleClick}
    />
  ));

  const renderRewards = () => {
    return (
      <CarouselWrapper>
        <Carousel
          elements={elements}
          config={{
            slidesPerView: 4,
            spaceBetween: 100,
            speed: 500,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            modules: [SwiperNavigation],
            className: 'default-carousel',
            loop: true,
            breakpoints: {
              992: { slidesPerView: 4 },
              768: { slidesPerView: 3 },
              320: { slidesPerView: 2 },
            },
          }}
        />
      </CarouselWrapper>
    );
  };

  return (
    <ReportSection
      title="Member Perks"
      hasContent={rewards.length > 0}
      sectionId="member-perks"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          flexDirection: 'row',
        }}
      >
        {rewards && renderRewards()}
      </Box>
    </ReportSection>
  );
};
