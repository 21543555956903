import { useEffect } from 'react';
import { DynamicQuestionsService, useSession } from '@ltvco/refresh-lib/v1';
import { useNavigate } from 'react-router-dom';

export function OnboardingPage() {
  const {
    session: { account },
  } = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Onboarding - NeighborWho';
  }, []);

  useEffect(() => {
    const sessionOnboardingCompleted = sessionStorage.getItem('dqs_completed');
    if (
      account?.account.user_settings.dqs_completed ||
      (sessionOnboardingCompleted && JSON.parse(sessionOnboardingCompleted))
    ) {
      navigate('/dashboard');
    }
  }, [account?.account]);

  return <DynamicQuestionsService />;
}
