import { constants } from 'appConstants';
const {
  links: { affiliateLink, aboutLink, doDontsLink, FaqLink, supportLink },
} = constants;

const memberPerksLink = 'https://www.neighborwho.com/rf/dashboard/rewards';
const requestApiAccessLink =
  'https://docs.google.com/forms/d/e/1FAIpQLScjlQNxFOTkE0ldROLnTOmYh4iVWll_omJxNmJK9394cEzVfw/viewform';
const needMoreReportsLink =
  'https://docs.google.com/forms/d/e/1FAIpQLScdRKgPBK4H5tJ_HgsQlQuw6tPQJwsis3eNGnR_25day9PubA/viewform';
const pressLink = 'https://www.neighborwho.com/press/';
const careersLink = 'https://www.ltvco.com/careers/';
const upgradePlanLink = 'https://www.neighborwho.com/rf/upgrade/plan';

export const generateFooterLinks = ({
  contactUsHandler,
  isUpgradeFormVariation,
}: {
  contactUsHandler: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  isUpgradeFormVariation?: boolean;
}) => [
  {
    title: 'RESOURCES',
    links: [
      { text: 'Affiliates', href: affiliateLink },
      { text: 'Member Perks', href: memberPerksLink },
      { text: 'Request API Access', href: requestApiAccessLink },
      {
        text: 'Need More Reports?',
        href: isUpgradeFormVariation ? upgradePlanLink : needMoreReportsLink,
      },
    ],
  },
  {
    title: 'SUPPORT',
    links: [
      { text: 'Customer Care', href: supportLink },
      { text: 'Contact Us', href: '#', onClick: contactUsHandler },
      { text: "Do's & Dont's", href: doDontsLink },
      { text: "FAQ's", href: FaqLink },
    ],
  },
  {
    title: 'COMPANY',
    links: [
      { text: 'About NeighborWho', href: aboutLink },
      { text: 'Press', href: pressLink },
      { text: 'Careers', href: careersLink },
    ],
  },
];
