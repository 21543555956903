import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import {
  EmailVerificationBlocker,
  EmailVerificationErrorModal,
  EmailVerificationInvalidModal,
  EmailVerificationSuccessModal,
  useAccount,
} from '@ltvco/refresh-lib/v1';

const VERIFICATION_EVENT_ACTION = 'view';
const VERIFICATION_EVENT_LABEL = 'email verification result modal';

export function EmailVerification({ children }: { children: JSX.Element }) {
  // Hooks
  const location = useLocation();
  const { data: account } = useAccount(true);
  const [statusParam, setStatusParam] = useQueryParam('status', StringParam);
  const { trackEvent } = useContext(AppConfig);

  // AB Tests
  const isInEmailBlockerVariation = useFeatureIsOn('mb5k-81');

  // State
  const [showEmailExpiredModal, setShowEmailExpiredModal] = useState(false);
  const [showEmailSuccessModal, setShowEmailSuccessModal] = useState(false);
  const [showEmailBlockerPage, setShowEmailBlockerPage] = useState(false);
  const [showEmailInvalidModal, setShowEmailInvalidModal] = useState(false);

  const isOnboardingPage = location.pathname.includes('/onboarding');

  useEffect(() => {
    if (!account) return;
    if (isOnboardingPage) setShowEmailBlockerPage(false);

    const isEmailVerified = account?.account.user_info?.verified_email;

    if (isEmailVerified && statusParam === 'success') {
      setStatusParam(undefined, 'replaceIn');
      setShowEmailSuccessModal(true);
      trackEvent(
        'email verification success',
        VERIFICATION_EVENT_ACTION,
        VERIFICATION_EVENT_LABEL
      );
    } else if (!isEmailVerified && statusParam === 'expired') {
      setStatusParam(undefined, 'replaceIn');
      setShowEmailExpiredModal(true);
      trackEvent(
        'email verification expired',
        VERIFICATION_EVENT_ACTION,
        VERIFICATION_EVENT_LABEL
      );
    } else if (!isEmailVerified && statusParam === 'invalid') {
      setStatusParam(undefined, 'replaceIn');
      setShowEmailInvalidModal(true);
      trackEvent(
        'email verification invalid',
        VERIFICATION_EVENT_ACTION,
        VERIFICATION_EVENT_LABEL
      );
    } else if (
      !isEmailVerified &&
      isInEmailBlockerVariation &&
      !showEmailExpiredModal &&
      !showEmailInvalidModal &&
      !isOnboardingPage
    ) {
      setShowEmailBlockerPage(true);
      trackEvent(
        'view blocker',
        VERIFICATION_EVENT_ACTION,
        'email verification blocker'
      );
    }
  }, [
    account,
    statusParam,
    setStatusParam,
    isInEmailBlockerVariation,
    isOnboardingPage,
  ]);

  return (
    <div>
      {isInEmailBlockerVariation && (
        <EmailVerificationBlocker open={showEmailBlockerPage} />
      )}
      <EmailVerificationErrorModal
        open={showEmailExpiredModal}
        onClose={() => setShowEmailExpiredModal(false)}
      />
      <EmailVerificationSuccessModal
        open={showEmailSuccessModal}
        onClose={() => setShowEmailSuccessModal(false)}
      />
      <EmailVerificationInvalidModal
        open={showEmailInvalidModal}
        onClose={() => setShowEmailInvalidModal(false)}
      />
      {children}
    </div>
  );
}
