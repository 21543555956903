import { useEffect, useState } from 'react';
import {
  isZeroed,
  useScrollToSectionOnNavigate,
  nameize,
  DateUtil,
} from '@ltvco/refresh-lib/utils';
import { Box, Stack } from '@ltvco/refresh-lib/theme';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import {
  type ReportNavigationMenuProps,
  EmailSection as EmailSectionV2,
  PhoneSection as PhoneSectionV2,
  AddressHistorySection as AddressHistorySectionV2,
  NeighborsSection as NeighborsSectionV2,
  RelativesSection as RelativesSectionV2,
  AssociatesSection as AssociatesSectionV2,
  EducationSection as EducationSectionV2,
  JobsSection as JobsSectionV2,
  SocialSection as SocialSectionV2,
  ReportNavigationMenu,
  ReportNavigationMobileMenu,
  PersonReportOverview,
  ReportActionsWithDateUpdated,
  ReportRating as ReportRatingV2,
  NameAncestrySection,
  NotesSection as NotesSectionV2,
  MaritalRecordsSection as MaritalRecordsSectionV2,
  AssetsSection,
  CriminalOrTrafficSection as CriminalOrTrafficSectionV2,
  BankruptcySection as BankruptcySectionV2,
  CourtRecords,
  CourtRecordsLoadingScreen,
  RelatedReportsSection as RelatedReportsSectionV2,
  LicensesAndPermitsSection as LicensesAndPermitsSectionV2,
  MonitoringBannerSection,
  ReportClaimingSection,
} from '@ltvco/refresh-lib/v2';
import type { ReportRouteProps } from './types';
import {
  getPersonNavLinkData,
  getPersonNavLinkDataV2,
} from 'navLinkData/personNavLinkData';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import { getClaimedReports } from 'utils/getClaimedReports';
import {
  DebugMenu,
  flattenBonusData,
  Owner,
  PdfSection,
  PersonReport as PersonReportType,
  ReportChangesOverview,
  ReportFactory,
  ReportLoading,
  ReportMonitor,
  ReportNullState,
  ReportOptions,
  useRemouladeReportSnapshot,
  useReport,
  useReportMonitors,
  useSession,
} from '@ltvco/refresh-lib/v1';

interface PersonReportProps extends ReportRouteProps {}

const reportType = 'person';

export function PersonReport({
  permalink,
  isMonitored = false,
}: PersonReportProps) {
  const {
    session: { account },
  } = useSession();

  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  // I want to move all this logic into a hook that handles the report fetching - Corey Gibson - 2024-06-20
  const { currentMonitors, reportIsMonitored } = useReportMonitors(permalink);
  const [initialIsMonitored, setInitialIsMonitored] = useState(isMonitored);

  useEffect(() => {
    if (reportIsMonitored) {
      setInitialIsMonitored(true);
    }
  }, [reportIsMonitored]);

  const monitorState = reportIsMonitored
    ? reportIsMonitored
    : initialIsMonitored;

  const coreResult = useReport(permalink, monitorState);
  const remouladeResult = useRemouladeReportSnapshot(permalink, monitorState);

  const flattenedRemouladeResult = remouladeResult
    ? { ...remouladeResult, data: remouladeResult.data?.data }
    : remouladeResult;

  const currentUserInfo = account?.account?.user_info;
  const queryResult = (
    initialIsMonitored ? flattenedRemouladeResult : coreResult
  ) as typeof coreResult;
  // If remoulade fails, we fall back to core. Most likely cause is race condition between creating monitor
  // and remoulade fetching data from core.
  useEffect(() => {
    if (remouladeResult.isError) {
      setInitialIsMonitored(false);
    }
  }, [remouladeResult.isError]);

  const zeroed = isZeroed(queryResult);
  const { hash } = useLocation();
  const isInSuggestedEmailVerification = useFeatureIsOn('oar-1146');
  const customTimeDelay = isInSuggestedEmailVerification ? 200 : undefined;
  useScrollToSectionOnNavigate(hash, queryResult, customTimeDelay);

  const [showBankruptcyData, setShowBankruptcyData] = useState<boolean>(false);
  const [showCriminalData, setShowCriminalData] = useState(false);
  const [isCourtRecordsLoading, setIsCourtRecordsLoading] = useState(false);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: false,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });

  const [activeClaiming, setActiveClaiming] = useState(false);
  const [disableClaiming, setDisableClaiming] = useState(false);
  const [claimedReportPermalink, setClaimedReportPermalink] =
    useState<string>('');

  useEffect(() => {
    setDisableClaiming(
      currentMonitors?.some((monitor: ReportMonitor) => {
        return (
          monitor.report_type === 'detailed_person_report' &&
          monitor.is_claimed &&
          permalink !== monitor.permalink
        );
      })
    );
    setActiveClaiming(
      currentMonitors?.some((monitor: ReportMonitor) => {
        return monitor.permalink === permalink && monitor.is_claimed;
      })
    );
  }, [currentMonitors, permalink]);

  const [report, setReport] = useState<PersonReportType | null>(null);
  const [reportBvId, setReportBvId] = useState<string>('');
  const [reportOwner, setReportOwner] = useState<Owner | null>(null);

  useEffect(() => {
    if (queryResult.isSuccess && queryResult.data) {
      setReport(ReportFactory.create(queryResult?.data, 'person'));
    }
  }, [queryResult.data, queryResult.isSuccess, reportOptions]);

  useEffect(() => {
    if (!currentMonitors) return;

    const REPORT_TYPE = 'detailed_person_report';
    const claimedReports = getClaimedReports(currentMonitors, REPORT_TYPE);

    if (claimedReports.length) {
      const mainPermalink = claimedReports[0].permalink;
      setClaimedReportPermalink(mainPermalink);
    } else {
      if (claimedReportPermalink !== '') {
        setClaimedReportPermalink('');
      }
    }
  }, [currentMonitors, claimedReportPermalink]);

  useEffect(() => {
    if (report) {
      const tempReportOwner = report.getOwner(reportOptions);
      if (!!tempReportOwner) {
        setReportOwner(tempReportOwner);
      }

      const queryParameters =
        report?.data?.meta?.request_info?.query_parameters;

      if (queryParameters) {
        const bvidObj = queryParameters.find(
          (param: { name: string }) => param.name === 'bvid'
        );
        setReportBvId(bvidObj?.['values']?.[0] || '');
      }
    }
  }, [report]);

  const hideCriminalBJLData = account?.account.user_settings
    .hide_criminal_records as boolean;

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={14} />;
  }

  if (zeroed) {
    return <ReportNullState />;
  }

  const reportUpgraded = queryResult.data.meta.report_upgraded;
  const revealFreeBonusData = showCriminalData && !isCourtRecordsLoading;

  let showReportClaiming = false;
  if (
    currentUserInfo?.first_name &&
    currentUserInfo.last_name &&
    report?.personName.includes(currentUserInfo.first_name) &&
    report?.personName.includes(currentUserInfo.last_name)
  ) {
    showReportClaiming = true;
  }

  const MonitoringComponent =
    showReportClaiming && !disableClaiming ? (
      <ReportClaimingSection
        reportPermaLink={permalink}
        reportType={reportType}
        reportIsMonitoredAndClaimed={activeClaiming}
      />
    ) : reportIsMonitored ? null : (
      <MonitoringBannerSection
        reportPermaLink={permalink}
        reportType={reportType}
      />
    );

  if (!reportOwner || !report) return <ReportLoading menuItems={14} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const {
    ownerName,
    nameInfo,
    emails,
    phones,
    educations,
    jobs,
    profiles,
    usernames,
    addresses,
    ownedAssets,
    marital,
    relatives,
    associates,
    neighbors,
    courts,
    bonusData,
  } = reportOwner as Owner;
  const flattenedBonusData = flattenBonusData(bonusData);

  const personNavLinkData = getPersonNavLinkData(
    reportOwner as Owner,
    showCriminalData,
    showBankruptcyData,
    hideCriminalBJLData,
    reportUpgraded,
    flattenedBonusData.length,
    relatives || []
  );

  const personNavLinkDataV2 = getPersonNavLinkDataV2(
    reportOwner as Owner,
    hideCriminalBJLData,
    revealFreeBonusData,
    relatives || []
  );

  const dob =
    reportOwner.identity?.dobs?.[0]?.date?.parsed?.year?.toString() ?? '';

  document.title = `${report.personName} - NeighborWho`;

  const date = new DateUtil();
  const reportUpdateDate = report.data.meta?.updated_at
    ? date.parseDateFromString(
        report.data.meta?.updated_at,
        'yyyy-MM-dd',
        'yyyy-MM-dd HH:mm:ss ZZZ'
      )
    : '';

  const delayForCourtRecords = 1000;

  function handleCourtRecordsLoadingScreen() {
    setIsCourtRecordsLoading(true);
    setTimeout(() => {
      setIsCourtRecordsLoading(false);
      setShowCriminalData(true);
    }, delayForCourtRecords * 7);
  }

  return (
    <Box>
      <Stack direction="row">
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
        >
          <ReportNavigationMenu
            reportType="Person Report"
            headerTitle={ownerName}
            menuItems={
              personNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
            }
          />
        </Box>

        <Box marginTop={0}>
          {reportIsMonitored && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={personNavLinkData}
            />
          )}

          <Box
            sx={{
              height: 30,
              backgroundColor: '#f8f8f8',
              position: 'sticky',
              marginBottom: '-10px',
              top: 52,
              zIndex: 10,
              display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
            }}
          />

          <>
            <ReportActionsWithDateUpdated
              reportType={reportType}
              reportTitle="Person"
              dateUpdated={reportUpdateDate}
              reportUpgraded={reportUpgraded}
            />
            <PersonReportOverview
              reportType={reportType}
              owner={reportOwner as Owner}
              personName={ownerName}
              personOverviewItemsData={{
                hideCriminalBJLData,
                phones,
                emails,
                addresses,
                relatives,
                images: reportOwner!.images,
                profiles,
                usernames,
                jobs,
                educations,
              }}
              dateUpdated={reportUpdateDate}
              reportUpgraded={revealFreeBonusData}
            />
          </>

          {MonitoringComponent}

          <PdfSection permalink={permalink} />

          <PhoneSectionV2
            personName={ownerName}
            phoneList={phones}
            permalink={permalink}
          />

          <EmailSectionV2
            personName={ownerName}
            emailList={emails}
            permalink={permalink}
          />

          <AddressHistorySectionV2
            addresses={addresses}
            personName={ownerName}
            permalink={permalink}
          />

          <SocialSectionV2
            personName={ownerName}
            profiles={profiles}
            usernames={usernames}
            permalink={permalink}
          />

          <NameAncestrySection
            firstName={nameize(nameInfo?.parsed?.first ?? null)}
            surName={nameize(nameInfo?.parsed?.last ?? null)}
            yearOfBirth={dob}
          />

          <RelativesSectionV2
            personName={ownerName}
            relatives={relatives || []}
            permalink={permalink}
          />

          <MaritalRecordsSectionV2
            personName={ownerName}
            marital={marital}
            permalink={permalink}
          />

          <AssociatesSectionV2
            personName={ownerName}
            associates={associates || []}
            permalink={permalink}
          />

          <NeighborsSectionV2
            personName={ownerName}
            neighbors={neighbors || []}
            permalink={permalink}
          />

          {!hideCriminalBJLData && (
            <>
              {revealFreeBonusData ? (
                <>
                  <CriminalOrTrafficSectionV2
                    criminalOrTrafficData={courts.criminal || []}
                    personName={ownerName}
                  />
                  <BankruptcySectionV2
                    personName={ownerName}
                    bankruptcyData={courts.bankruptcy || []}
                  />
                  <LicensesAndPermitsSectionV2
                    personName={ownerName}
                    bonusData={bonusData}
                  />
                </>
              ) : (
                <>
                  {isCourtRecordsLoading ? (
                    <CourtRecordsLoadingScreen
                      personName={ownerName}
                      delayDuration={delayForCourtRecords}
                    />
                  ) : (
                    <CourtRecords
                      handleCourtRecordsLoadingScreen={
                        handleCourtRecordsLoadingScreen
                      }
                    />
                  )}
                </>
              )}
            </>
          )}

          <JobsSectionV2
            personName={ownerName}
            jobs={jobs}
            permalink={permalink}
          />

          <EducationSectionV2
            personName={ownerName}
            educations={educations}
            permalink={permalink}
          />

          <AssetsSection
            personName={ownerName}
            ownedAssets={ownedAssets}
            permalink={permalink}
          />

          <NotesSectionV2 permalink={permalink} />

          <RelatedReportsSectionV2
            reportType="person"
            personName={ownerName}
            potentialOwners={report.data.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
          />

          <ReportRatingV2
            rating={report.data.meta?.rating || null}
            report_type={reportType}
          />
        </Box>
      </Stack>

      <ReportNavigationMobileMenu
        reportType="Person Report"
        headerTitle={ownerName}
        menuItems={
          personNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
        }
      />

      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </Box>
  );
}
