import { Text, Link, Button, Card } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { HouseRounded } from '@mui/icons-material';

export function SellYourHouseCTA() {
  const { isMobile, isTablet } = useScreenSize();

  return (
    <Card variant="outlined" sx={{ textAlign: 'center', height: '14.375rem' }}>
      <HouseRounded sx={{ fontSize: '3.125rem', alignSelf: 'center' }} />
      <Text variant="h4" marginTop={1} alignSelf="center">
        Get Cash For Your House
      </Text>
      <Text variant="body2" marginBottom={1} alignSelf="center">
        Fill Out The Form To Get Started On Your FREE CASH OFFER Today! No
        Repairs. No Fees.
      </Text>

      <Link
        href={
          'https://propertyleads.go2cloud.org/aff_c?offer_id=2&aff_id=24&url_id=7&aff_sub=neighborwho&aff_sub2=partner&aff_sub3=propertyleads_internal&aff_sub4=&aff_sub5='
        }
        target="_blank"
        alignSelf="center"
        sx={{ width: isMobile ? '100%' : isTablet ? '201px' : '220px' }}
      >
        <Button
          variant="contained"
          color="success"
          size={isMobile ? 'small' : 'large'}
        >
          Sell Your House
        </Button>
      </Link>
    </Card>
  );
}
