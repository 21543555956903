import { SvgIcon, SvgIconProps } from '@ltvco/refresh-lib/theme';

export const NWLogo = (props: SvgIconProps) => {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <circle cx="20" cy="20" r="20" fill="#E6EEF7" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.2904 8.18498C28.5442 7.93834 28.9558 7.93834 29.2096 8.18498L31.8096 10.7113C31.9315 10.8297 32 10.9904 32 11.1579V31.3684C32 31.6673 31.7843 31.9253 31.4831 31.9866C31.1819 32.0478 30.8778 31.8954 30.7545 31.6215L25.5545 20.0727C25.5186 19.9928 25.5 19.9066 25.5 19.8195V11.1579C25.5 10.9904 25.5685 10.8297 25.6904 10.7113L28.2904 8.18498ZM26.8 11.4195V19.6874L30.7 28.349V11.4195L28.75 9.52476L26.8 11.4195Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.497 10.5135C22.7866 10.4522 23.079 10.6047 23.1976 10.879L31.9476 31.1142C32.0321 31.3097 32.0135 31.5352 31.8981 31.7138C31.7826 31.8924 31.5859 32 31.375 32H26.375C26.1267 32 25.9019 31.8513 25.8024 31.6211L22.0524 22.9489C22.0178 22.8689 22 22.7826 22 22.6954V11.1324C22 10.8331 22.2074 10.5747 22.497 10.5135ZM23.25 14.1554V22.5631L26.7838 30.7353H30.4194L23.25 14.1554Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5757 8.18498C19.8101 7.93834 20.1899 7.93834 20.4243 8.18498L22.8243 10.7113C22.9368 10.8297 23 10.9904 23 11.1579V31.3684C23 31.6673 22.8009 31.9253 22.5228 31.9866C22.2448 32.0478 21.9641 31.8954 21.8503 31.6215L17.0503 20.0727C17.0171 19.9928 17 19.9066 17 19.8195V11.1579C17 10.9904 17.0632 10.8297 17.1757 10.7113L19.5757 8.18498ZM18.2 11.4195V19.6874L21.8 28.349V11.4195L20 9.52476L18.2 11.4195Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.497 10.5135C13.7866 10.4522 14.079 10.6047 14.1976 10.879L22.9476 31.1142C23.0321 31.3097 23.0135 31.5352 22.8981 31.7138C22.7826 31.8924 22.5859 32 22.375 32H17.375C17.1267 32 16.9019 31.8513 16.8024 31.6211L13.0524 22.9489C13.0178 22.8689 13 22.7826 13 22.6954V11.1324C13 10.8331 13.2074 10.5747 13.497 10.5135ZM14.25 14.1554V22.5631L17.7838 30.7353H21.4194L14.25 14.1554Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7904 8.18499C11.0442 7.93834 11.4558 7.93834 11.7096 8.18499L14.3096 10.7113C14.4315 10.8297 14.5 10.9904 14.5 11.1579V31.3684C14.5 31.7172 14.209 32 13.85 32H8.65C8.29101 32 8 31.7172 8 31.3684V11.1579C8 10.9904 8.06848 10.8297 8.19038 10.7113L10.7904 8.18499ZM9.3 11.4195V30.7368H13.2V11.4195L11.25 9.52477L9.3 11.4195Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
