import { ValidReportTypes } from '@ltvco/refresh-lib/utils';
import { Text, Grid } from '@ltvco/refresh-lib/theme';
import {
  ClaimedReportsCards,
  RecentReportSerialized,
} from '@ltvco/refresh-lib/v1';
interface ClaimedReportsProps {
  claimedReports: RecentReportSerialized[];
}

export function MyClaimedReports({ claimedReports }: ClaimedReportsProps) {
  const ClaimedReport = claimedReports?.map((report) => (
    <Grid item sx={{ minWidth: '14rem', maxWidth: '14rem' }} key={report.id}>
      <ClaimedReportsCards
        reportPermaLink={report.details.permalink}
        detailsCityState={report.detailsCityState}
        reportTitle={report.reportData}
        createdDate={report.createdDate}
        reportType={report.reportTypeFormatted as ValidReportTypes}
      />
    </Grid>
  ));
  return (
    <Grid
      container
      direction="column"
      alignItems="start"
      justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
    >
      <Grid item width="185px">
        <Text variant="h3" mb={0}>
          Claimed Properties
        </Text>
      </Grid>
      <Grid
        marginTop={2.5}
        direction="row"
        container
        wrap="nowrap"
        sx={{
          overflowX: 'scroll',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
        gap="2rem"
      >
        {ClaimedReport}
      </Grid>
    </Grid>
  );
}
