import { useContext } from 'react';
import { useQueryParams } from 'utils/useQueryParams';
import { useQueryParams as useQueryParamsBBQ } from 'use-query-params';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isZeroed } from '@ltvco/refresh-lib/utils';
import { useQuery } from '@ltvco/refresh-lib/vendors';
import {
  AppConfig,
  AppConstants,
  ReportContextProvider,
} from '@ltvco/refresh-lib/ctx';
import {
  Grid,
  Box,
  Stack,
  styled,
  Tab,
  Text,
  TabsNavigation,
  useTheme,
} from '@ltvco/refresh-lib/theme';
import {
  reportGetters,
  PropertyReport,
  RecentReportSerialized,
  PropertyResults,
  MarketInsights,
  SerializedListingsData,
  useReport,
  useSearchListings,
  buildRecentlySoldHomeTab,
  buildPreForeclosureTab,
  LoadingDots,
  NeighborhoodWatch,
  EmptyNeighborhoodReportPage,
  Tophap,
  ReportNullState,
  getMarketData,
  getNeighborhoodData,
  getAreaData,
  serializedTypes,
  MarketTabConfigProps,
  WidgetsWithSectionTitleProps,
  SearchData,
} from '@ltvco/refresh-lib/v1';
import { SearchPropertyForm } from '@ltvco/refresh-lib/v2';
import { MapRounded } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import {
  generateQueryConfig,
  stringifyParams,
  useRecentReports,
} from '../../../components/BBQ';
import {
  ExtendedTabData,
  generateFullAddressFromParams,
  generateTabs,
  getReportDetails,
  optimisticRenderingForNewReports,
} from './utils';

const TextWithIcon = styled(Text)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4.5),

  svg: {
    marginRight: theme.spacing(1),
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  width: '185px',
  borderBottom: 'none',
  paddingBottom: theme.spacing(1),
}));

const queryConfig = generateQueryConfig();
const reportsToDisplay = 5;

export const NeighborhoodReportSection = () => {
  /////////////////////////////
  // States
  /////////////////////////////
  const [permalink, setPermalink] = useState('');
  const [
    shouldRequestReportFromQueryParams,
    setShouldRequestReportFromQueryParams,
  ] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(0);
  const [reportsList, setReportsList] = useState<RecentReportSerialized[]>([]);
  const [isManualLoading, setIsManualLoading] = useState(true);

  /////////////////////////////
  // Hooks
  /////////////////////////////
  const queryParams = useQueryParams();
  const theme = useTheme();
  const { logError, routingUtils } = useContext(AppConfig);
  const {
    featureFlags: { propertyReportFlags },
    keys: { topHapApiKey },
    links: { topHapBaseUrl },
  } = useContext(AppConstants);
  const queryReportResult = useReport(permalink);
  const zeroed = isZeroed(queryReportResult);
  const [bbqQueryParams] = useQueryParamsBBQ(queryConfig);
  const filteredParams = stringifyParams({ ...bbqQueryParams });
  filteredParams.report_type = 'property_report';
  const { data: recentReportsQueryData, isLoading: recentReportsIsLoading } =
    useRecentReports(filteredParams);
  const basePage = queryParams?.get('from') || 'dashboard.neighborhood-report';
  queryParams.set('report_flags', JSON.stringify(propertyReportFlags));
  const propertyReportGetter = reportGetters.property;

  // Fetch initial data from queryParams
  useQuery({
    queryKey: ['neighborhoodReport'],
    queryFn: () => propertyReportGetter({ queryParams }),
    enabled: shouldRequestReportFromQueryParams,
    onSuccess: (data) => setPermalink(data?.report?.permalink),
    onError: (error: Error) => logError('Neighborhood Report', error),
  });

  const firstProperty = queryReportResult?.data?.entities?.properties?.[0];
  const searchData = {
    // 'byram' is the name of the DD Plan which includes Listhub data
    // to the listings endpoints response (WC - 2023/10/05).
    dd_plan: 'byram',
    rentals_limit: '10',
    pfc_limit: '10',
    listings_limit: '10',
    city: firstProperty?.parcel_address?.parsed?.city,
    state: firstProperty?.state,
    brand: 'nw',
  };
  let { data: searchListingsResult = [] } = useSearchListings(
    searchData,
    Boolean(firstProperty?.parcel_address?.parsed?.city && firstProperty?.state)
  );

  /////////////////////////////
  // Variables
  /////////////////////////////

  const topHapInstance = new Tophap() as Tophap;
  const optionalProps = {
    label: 'Every home belongs to an area. Explore yours.',
    alignment: 'start',
    buttonWidth: '20%',
    inputWidth: '50%',
    customPx: 0,
    showFcra: false,
    customLabelStyles: {
      fontSize: '16px',
      marginBottom: '16px',
      display: 'inline-block',
      lineHeight: '1.5',
      letterSpacing: '0.00938em',
      fontFamily: '“Roboto”,“Helvetica”,“Arial”,sans-serif',
    },
  };

  if (
    !permalink &&
    !recentReportsIsLoading &&
    reportsList?.length &&
    !isManualLoading
  ) {
    const firstPermalink = reportsList?.[0].id;
    setPermalink(firstPermalink);
    setSearchParams({ permalink: firstPermalink });
  }

  // Property Report data
  const report = new PropertyReport(queryReportResult?.data);
  const property = report.data.properties[0];
  const reportDetails = getReportDetails(property);
  const fullAddress = generateFullAddressFromParams(queryParams);
  const displayAddress = report.data.address || fullAddress;

  // NearbyProperties data
  const listingsFetchResults = (searchListingsResult as SerializedListingsData)
    ?.listing_teaser;
  const preforeclosureData = listingsFetchResults?.preforeclosures || [];
  const totalPreforeclosures =
    listingsFetchResults?.meta?.available?.market?.preforeclosures || 0;

  let nearbyPropertiesTabs = {} as MarketTabConfigProps[];

  const navigate = useNavigate();

  const listingsNavigateAction = (
    property: serializedTypes.SerializedProperty,
    type?: string
  ) => {
    const { city, state, zip5 } = property?.parcel_address?.parsed || {
      city: '',
      state: '',
      zip5: '',
    };
    const url = `/search/listing?city=${city}&state=${state}&zip5=${zip5}&search_type=${
      type || 'pre_foreclosures'
    }`;
    navigate(url);
  };

  if (property) {
    nearbyPropertiesTabs = [
      buildRecentlySoldHomeTab({
        property,
        data: property?.comparables || [],
      }),
      buildPreForeclosureTab({
        property,
        data: preforeclosureData,
        navigateAction: listingsNavigateAction,
        totalDataValues: totalPreforeclosures,
      }),
    ];
  }

  // NeighborhoodWatch data
  const sexOffenders = report?.data?.sex_offenders;
  const propertyReportGeoLat: number | undefined =
    property?.parcel_address?.geo?.latitude ?? undefined;
  const propertyReportGeoLong: number | undefined =
    property?.parcel_address?.geo?.longitude ?? undefined;

  // Widgets data
  const checksForUrl: boolean =
    !!reportDetails?.fullAddress && !!topHapApiKey && !!topHapBaseUrl;

  const allWidgetsData: WidgetsWithSectionTitleProps[] = [
    {
      widgetData: getNeighborhoodData({
        checksForUrl,
        property: reportDetails,
        topHapApiKey: topHapApiKey || '',
        topHapBaseUrl: topHapBaseUrl || '',
        getTopHapWidgetUrl: topHapInstance?.getTopHapWidgetUrl,
      }),
      sectionTitle: 'Neighborhood',
    },
    {
      widgetData: getMarketData({
        checksForUrl,
        property: reportDetails,
        topHapApiKey: topHapApiKey || '',
        topHapBaseUrl: topHapBaseUrl || '',
        getTopHapWidgetUrl: topHapInstance?.getTopHapWidgetUrl,
      }),
      sectionTitle: 'Market',
    },
    {
      widgetData: getAreaData({
        checksForUrl,
        property: reportDetails,
        topHapApiKey: topHapApiKey || '',
        topHapBaseUrl: topHapBaseUrl || '',
        getTopHapWidgetUrl: topHapInstance?.getTopHapWidgetUrl,
      }),
      sectionTitle: 'Area',
    },
  ];

  let currentTabs = [] as ExtendedTabData[];

  if (reportsList?.length && topHapInstance) {
    currentTabs = generateTabs({
      tabsReports: reportsList,
      reportDetails,
      address: displayAddress,
      sexOffenders,
      propertyReportGeoLat,
      propertyReportGeoLong,
      allWidgetsData,
      topHapInstance: topHapInstance,
    });
  }

  /////////////////////////////
  // Lifecycle Functions
  /////////////////////////////
  useEffect(() => {
    document.title = 'Neighborhood Report - NeighborWho';

    const manualLoadingTimer = setTimeout(() => {
      setIsManualLoading(false);
    }, 5000);

    return () => clearTimeout(manualLoadingTimer);
  }, []);

  useEffect(() => {
    if (!recentReportsIsLoading) {
      const recentReports =
        (recentReportsQueryData?.reports as RecentReportSerialized[]) || [];

      const filteredRecentReports = recentReports
        .filter((report) => !Boolean(report.details?.no_entities))
        .slice(0, reportsToDisplay);

      setReportsList(filteredRecentReports);
    }
  }, [recentReportsQueryData?.reports]);

  // Update the parmalink based on the query params
  useEffect(() => {
    if (queryParams.size === 0) {
      setPermalink('');
      setShouldRequestReportFromQueryParams(false);
    } else if (queryParams?.get('permalink')) {
      setPermalink(queryParams?.get('permalink') || '');
    } else if (queryParams?.get('address')) {
      setShouldRequestReportFromQueryParams(true);
    }
  }, [queryParams]);

  // Update the active tab based on permalink and reports list changes
  useEffect(() => {
    const highlightedTabIndex = reportsList.findIndex(
      (report) => report.id === permalink
    );

    setActiveTab(highlightedTabIndex);
  }, [reportsList, permalink]);

  /////////////////////////////
  // Helper Functions
  /////////////////////////////
  const handleReportChange = (permalink: string, searchParams?: string) => {
    let baseUrl = '/dashboard/neighborhood-report?';
    if (searchParams?.length) {
      baseUrl = `${baseUrl}${searchParams}&`;
    }
    const url = `${baseUrl}permalink=${permalink}&from=dashboard.neighborhood-report`;
    routingUtils.navigate(url);
  };

  /////////////////////////////
  // Render Functions
  /////////////////////////////
  if (zeroed) {
    return <ReportNullState />;
  }

  if (!report) {
    return <h1>[[Placeholder: No Report Data]]</h1>;
  }

  if (
    queryReportResult.status === 'loading' ||
    recentReportsIsLoading ||
    isManualLoading
  ) {
    return (
      <Stack sx={{ marginTop: '30%', height: '100vh' }}>
        <LoadingDots count={6} />
      </Stack>
    );
  }

  if (queryReportResult?.data?.meta?.no_entities) {
    const options = queryReportResult?.data?.entities?.options;

    return (
      <ReportContextProvider permalink={permalink} reportType="neighborhood">
        <Grid
          sx={{
            flexDirection: 'column',
            paddingBottom: '20px',
          }}
          container
        >
          <PropertyResults
            searchAddress={report?.data?.address || fullAddress}
            options={options}
            fromDashboard={basePage}
            pageOptions={{
              disableSubheadline: Boolean(options?.properties?.length < 1),
            }}
          />
        </Grid>
      </ReportContextProvider>
    );
  }

  return (
    <>
      <Box>
        <TextWithIcon variant="h1">
          <MapRounded
            sx={{ color: theme.palette.primary.main, fontSize: '2.5rem' }}
          />
          Neighborhood Report
        </TextWithIcon>
        <Box>
          <SearchPropertyForm
            options={optionalProps}
            onSuccess={(data: SearchData) => {
              setReportsList(
                optimisticRenderingForNewReports(
                  reportsList,
                  data,
                  reportsToDisplay
                ) || []
              );
              handleReportChange(data?.permalink, data?.searchParams);
            }}
          />
        </Box>
        {!!reportsList?.length && (
          <Box pt={4.5} pb={2}>
            <TabsNavigation
              externalActiveTab={activeTab}
              textColor="primary"
              indicatorColor="primary"
              onTabChanged={(currentTab) => {
                handleReportChange(currentTabs[currentTab].permalink);
                setActiveTab(currentTab);
              }}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              aria-label="reports tabs"
              tab={StyledTab}
              tabs={currentTabs}
              sx={{
                '.MuiButtonBase-root': {
                  textAlign: 'left',
                  alignItems: 'start',
                },
              }}
            />
            <NeighborhoodWatch
              sexOffendersList={sexOffenders}
              address={displayAddress}
              addressLat={propertyReportGeoLat}
              addressLong={propertyReportGeoLong}
            />
            {property && (
              <MarketInsights
                propertyAddress={displayAddress}
                tabs={nearbyPropertiesTabs?.filter((item) => !!item)}
                hideSectionOptions={true}
              />
            )}
          </Box>
        )}
      </Box>
      {!reportsList?.length && <EmptyNeighborhoodReportPage />}
    </>
  );
};
