import { useEffect } from 'react';
import { PerksPage } from '@ltvco/refresh-lib/v1';

export function RewardsPage() {
  useEffect(() => {
    document.title = 'Member Perks - NeighborWho';
  }, []);

  return <PerksPage />;
}
