import * as yup from 'yup';

const PHONE_VALIDATION_REGEX = /^(\d{1} )?(\(\d{3}\)) (\d{3})-(\d{4})$/;

export const signUpSchema = yup.object().shape({
  first_name: yup.string().trim().required(),
  last_name: yup.string().trim().required(),
  address: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/)
    .required(),
  apt: yup.string(),
  city: yup
    .string()
    .matches(/^[a-zA-Z ]*$/)
    .required(),
  state: yup.string().required('State required'),
  zip: yup
    .string()
    .matches(/^\d{5}$/)
    .required(),
  tokenized_ssn: yup
    .string()
    .matches(/^\d{9}$/)
    .required(),
  tokenized_ssn_confirmation: yup
    .string()
    .test('ssn-match', 'SSN must match', function (v) {
      return this.parent.tokenized_ssn === v;
    })
    .required('Must match the social security number'),
  phone_number: yup
    .string()
    .matches(PHONE_VALIDATION_REGEX, 'Please enter a valid phone number.')
    .required(),
  tokenized_dob: yup.string().required('Please submit a valid date'),
  terms_and_conditions: yup.boolean().oneOf([true]).required(),
});
