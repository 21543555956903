import { useEffect } from 'react';
import { useLimitedPlanInfo } from 'utils/useLimitedPlanInfo';
import { Header } from '@ltvco/refresh-lib/v2';
import { checkPlanVariations } from 'utils/checkPlanVariations';

type HeaderParentProps = {
  handleSearchSuccess: any;
  changeSingleReportVariation: (variation: boolean) => void;
};

export const HeaderParent = ({
  handleSearchSuccess,
  changeSingleReportVariation,
}: HeaderParentProps) => {
  const { planName } = useLimitedPlanInfo();
  const { isInSingleReportVariation } = checkPlanVariations(planName);

  useEffect(() => {
    changeSingleReportVariation(isInSingleReportVariation);
  }, [isInSingleReportVariation]);

  return <Header onSearchSuccess={handleSearchSuccess} shouldFireGAUpsell />;
};
