import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { ContactOptionsModal } from '@ltvco/refresh-lib/v1';
import { constants } from 'appConstants';

type ContactOptionsModalParent = {
  isContactOptionsModalOpen: boolean;
  setIsContactOptionsModalOpen: () => void;
  openConfirmCancelModal: () => void;
};

export const ContactOptionsModalParent = ({
  isContactOptionsModalOpen,
  setIsContactOptionsModalOpen,
  openConfirmCancelModal,
}: ContactOptionsModalParent) => {
  const isInDownsellVariation = useFeatureIsOn('prop-2185');

  const newBillingOptions = constants.config.newBillingOptions;

  return (
    <ContactOptionsModal
      isOpen={isContactOptionsModalOpen}
      setIsOpen={setIsContactOptionsModalOpen}
      openConfirmCancelModal={openConfirmCancelModal}
      customBillingTopics={
        isInDownsellVariation ? newBillingOptions : undefined
      }
    />
  );
};
