import { useContext, useEffect, useState } from 'react';
import {
  isNeighborSpoofed,
  formatPhone,
  isZeroed,
  useScrollToSectionOnNavigate,
  unformatPhone,
  DateUtil,
} from '@ltvco/refresh-lib/utils';
import { Stack, Box } from '@ltvco/refresh-lib/theme';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import {
  PhoneSection as PhoneSectionV2,
  EmailSection as EmailSectionV2,
  AddressHistorySection as AddressHistorySectionV2,
  SocialSection as SocialSectionV2,
  JobsSection as JobsSectionV2,
  EducationSection as EducationSectionV2,
  ReportRating as ReportRatingV2,
  RelatedReportsSection as RelatedReportsSectionV2,
  NotesSection as NotesSectionV2,
  AssetsSection as AssetsSectionV2,
  PotentialOwners as PotentialOwnersV2,
  ReportNavigationMenu,
  ReportNavigationMobileMenu,
  ReportNavigationMenuProps,
} from '@ltvco/refresh-lib/v2';
import type { ReportRouteProps } from './types';
import {
  getPhoneNavLinkDataV2,
  getPhoneNavLinkData,
} from 'navLinkData/phoneNavLinkData';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import questionableCallerGifUrl from '../../images/caller_questionable.gif';
import telemarketerCallerGifUrl from '../../images/caller_telemarketer.gif';
import legitCallerGifUrl from '../../images/caller_legit.gif';
import { ReportActionsWithDateUpdated } from '@ltvco/refresh-lib/v2';
import {
  CommentsSection,
  commonTypes,
  DebugMenu,
  Monitoring,
  NeighborSpoofing,
  NuisanceCaller,
  PhoneFraudScanSection,
  PhoneOverview,
  PotentialSpam,
  ReportChangesOverview,
  ReportClaiming,
  ReportFactory,
  ReportLoading,
  ReportMonitor,
  ReportNullState,
  ReportOptions,
  SearchVolume,
  usePhoneSearchData,
  useRemouladeReportSnapshot,
  useReport,
  useReportMonitors,
  useSession,
} from '@ltvco/refresh-lib/v1';

interface PhoneReportProps extends ReportRouteProps {}

const reportType = 'phone';

export function PhoneReport({
  permalink,
  isMonitored = false,
}: PhoneReportProps) {
  const {
    session: { account },
  } = useSession();
  const { logError } = useContext(AppConfig);

  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  const { currentMonitors, reportIsMonitored } = useReportMonitors();
  const [initialIsMonitored, setInitialIsMonitored] = useState(isMonitored);

  useEffect(() => {
    if (reportIsMonitored) {
      setInitialIsMonitored(true);
    }
  }, [reportIsMonitored]);
  const monitorState = reportIsMonitored
    ? reportIsMonitored
    : initialIsMonitored;

  const coreResult = useReport(permalink, monitorState);
  const remouladeResult = useRemouladeReportSnapshot(permalink, monitorState);
  const flattenedRemouladeResult = remouladeResult
    ? { ...remouladeResult, data: remouladeResult.data?.data }
    : remouladeResult;

  const currentUserInfo = account?.account?.user_info;
  const queryResult = (
    initialIsMonitored ? flattenedRemouladeResult : coreResult
  ) as typeof coreResult;
  // If remoulade fails, we fall back to core. Most likely cause is race condition between creating monitor
  // and remoulade fetching data from core.
  useEffect(() => {
    if (remouladeResult.isError) {
      setInitialIsMonitored(false);
    }
  }, [remouladeResult.isError]);

  const zeroed = isZeroed(queryResult);
  const { hash, search } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: false,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };
  const [activeMonitor, setActiveMonitor] = useState(false);
  const [activeClaiming, setActiveClaiming] = useState(false);

  useEffect(() => {
    setActiveClaiming(
      currentMonitors?.some((monitor: ReportMonitor) => {
        return monitor.permalink === permalink && monitor.is_claimed;
      })
    );
  }, [currentMonitors, permalink]);

  const { data: phoneSearchData } = usePhoneSearchData(
    logError,
    queryResult?.data?.meta?.search_data?.phone_number
  );

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={15} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  const report = ReportFactory.create(queryResult?.data, 'phone');

  const showReportClaiming =
    unformatPhone(currentUserInfo?.phone_number || '') === report.phone;

  const MonitoringComponent = showReportClaiming ? (
    <ReportClaiming
      permalink={permalink}
      reportType={reportType}
      reportIsMonitoredAndClaimed={activeClaiming}
    />
  ) : (
    <Monitoring permalink={permalink} reportType={reportType} />
  );

  const neighborSpoofed = isNeighborSpoofed(
    report.data,
    account?.account?.user_info.phone_number
  );

  if (!report) return <ReportLoading menuItems={15} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const owner = report.getOwner(reportOptions);

  const {
    ownerName,
    emails,
    educations,
    jobs,
    profiles,
    usernames,
    images,
    phones,
    addresses,
    ownedAssets,
    relatives,
  } = owner;

  const { risk, connectivity, is_valid } = report.supportingPhone || {};
  const {
    fraud_score,
    is_prepaid,
    is_voip,
    is_risky,
    is_recent_abuse,
    is_do_not_call,
  } = risk || {};

  const is_active = connectivity?.is_active;

  const showPhoneFraudScan =
    report.supportingPhone != null && risk != null && connectivity != null;

  const queryParams = new URLSearchParams(search);
  const fallbackPhone = queryParams.get('phone') || '';
  const phoneNumber = formatPhone(report.phone) ?? formatPhone(fallbackPhone);

  const otherPhones = phones.filter(
    (phone: commonTypes.Phone) => phone.number !== fallbackPhone
  );

  const business = report?.data?.rawData?.entities?.businesses[0];
  const businessName = business?.names[0]?.name ?? '';

  let potentialOwnersNumber = 0;

  if (report.data.people.length > 0) {
    potentialOwnersNumber = report.data.people.length;
  } else if (businessName) {
    potentialOwnersNumber = 1;
  }

  const { main_line_type, num_searches, spam_score } = report.data;
  const searchCount = phoneSearchData?.total_volume || num_searches || 0;

  const phoneNavLinkDataGetter = getPhoneNavLinkDataV2;

  const phoneNavLinkDataV2 = phoneNavLinkDataGetter(owner, {
    potentialOwnersLength: potentialOwnersNumber,
    searchVolumeLength: searchCount > 0 ? 1 : 0,
    commentsLength: report.data.comments?.length ?? 0,
    neighborSpoofingLength: neighborSpoofed ? 1 : 0,
    spamReportsCount: report.data.spam_reports_count ?? 0,
  });

  const phoneNavLinkData = getPhoneNavLinkData(owner, {
    potentialOwnersLength: potentialOwnersNumber,
    searchVolumeLength: searchCount > 0 ? 1 : 0,
    commentsLength: report.data.comments?.length ?? 0,
    neighborSpoofingLength: neighborSpoofed ? 1 : 0,
    spamReportsCount: report.data.spam_reports_count ?? 0,
  });

  const comments = report.data.comments || [];
  const carrier = report.data.meta?.search_data?.carrier;
  const date = new DateUtil();
  const reportUpdateDate = report.data.meta?.updated_at
    ? date.parseDateFromString(
        report.data.meta?.updated_at,
        'yyyy-MM-dd',
        'yyyy-MM-dd HH:mm:ss ZZZ'
      )
    : '';

  document.title = `${formatPhone(phoneNumber)} - NeighborWho`;

  return (
    <Box>
      <Stack direction="row">
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
        >
          <ReportNavigationMenu
            reportType="Phone Report"
            headerTitle={formatPhone(phoneNumber)}
            menuItems={
              phoneNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
            }
          />
        </Box>
        <Box marginTop={0}>
          {activeMonitor && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={phoneNavLinkData}
            />
          )}

          <Box
            sx={{
              height: 30,
              backgroundColor: '#f8f8f8',
              position: 'sticky',
              marginBottom: '-10px',
              marginX: -1,
              top: 52,
              zIndex: 10,
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'block',
              },
            }}
          />

          <ReportActionsWithDateUpdated
            reportType={reportType}
            reportTitle="Phone"
            dateUpdated={reportUpdateDate}
            reportUpgraded={false}
          />

          {potentialOwnersNumber === 0 && <PotentialSpam />}
          <PhoneOverview
            reportType={reportType}
            phone={formatPhone(phoneNumber)}
            personName={ownerName || businessName}
            neighborSpoofed={neighborSpoofed}
            phoneOverviewItemsData={{
              comments,
              mainLineType: main_line_type,
              numSearches: searchCount,
              spamScore: spam_score,
              carrier,
              peopleCount: potentialOwnersNumber,
            }}
            Monitoring={MonitoringComponent}
            showHighConfidenceToggle
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) => {
              handleSetReportOptions({ showHighConfidenceDataOnly: value });
            }}
            showBlockNumberSection
          />

          <PhoneFraudScanSection
            fraudScore={fraud_score}
            validStatus={is_valid}
            prepaid={is_prepaid}
            voip={is_voip}
            risky={is_risky}
            recentAbuse={is_recent_abuse}
            active={is_active}
            doNotCall={is_do_not_call}
            phoneNumber={phoneNumber}
            showPhoneFraudScan={showPhoneFraudScan}
            showReportCTA
          />
          <NeighborSpoofing phoneReportData={report.data} />
          <SearchVolume searchCount={searchCount} />
          <NuisanceCaller
            reportData={report.data}
            callerSourceMap={{
              telemarketer: telemarketerCallerGifUrl,
              questionable: questionableCallerGifUrl,
              legit: legitCallerGifUrl,
            }}
          />
          <CommentsSection reportData={report.data} permalink={permalink} />

          <PotentialOwnersV2
            potentialOwners={report.data.people || []}
            potentialOwnerIndex={reportOptions.potentialOwnerIndex}
            setPotentialOwnerIndex={(index: number) => {
              handleSetReportOptions({ potentialOwnerIndex: index });
            }}
            personName={ownerName || report.data.formatted_number}
            permalink={permalink}
            business={business}
          />
          <PhoneSectionV2
            personName={ownerName || report.data.formatted_number}
            phoneList={otherPhones}
            permalink={permalink}
            alternateTitle="Other Phone Numbers"
          />
          <EmailSectionV2
            personName={ownerName || report.data.formatted_number}
            emailList={emails}
            permalink={permalink}
          />
          <AddressHistorySectionV2
            addresses={addresses}
            personName={ownerName || report.data.formatted_number}
            permalink={permalink}
          />
          <SocialSectionV2
            personName={ownerName || report.data.formatted_number}
            profiles={profiles}
            usernames={usernames}
            permalink={permalink}
          />
          <JobsSectionV2
            personName={ownerName || report.data.formatted_number}
            jobs={jobs}
            permalink={permalink}
          />

          <EducationSectionV2
            personName={ownerName || report.data.formatted_number}
            educations={educations}
            permalink={permalink}
          />
          <AssetsSectionV2
            personName={ownerName || report.data.formatted_number}
            ownedAssets={ownedAssets}
            permalink={permalink}
          />

          <NotesSectionV2 permalink={permalink} />
          <RelatedReportsSectionV2
            reportType="phone"
            personName={ownerName || report.data.formatted_number}
            potentialOwners={report.data.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
          />
          <ReportRatingV2
            rating={report.data.meta?.rating || null}
            report_type={reportType}
          />
        </Box>
      </Stack>
      <ReportNavigationMobileMenu
        reportType="Phone Report"
        headerTitle={formatPhone(phoneNumber)}
        menuItems={phoneNavLinkDataV2 as ReportNavigationMenuProps['menuItems']}
      />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </Box>
  );
}
