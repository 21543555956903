import { Box, Divider, styled } from '@ltvco/refresh-lib/theme';
import { formatPhone } from '@ltvco/refresh-lib/utils';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { useOxford } from '../Oxford/Oxford';
import { Header } from './Header';
import { Navigation } from '../Navigation';
import { FormStepOne } from './FormStepOne';
import { FormStepTwo } from './FormStepTwo';
import { signUpSchema } from 'utils/schemaForms';
import { SignUpFormValues } from './types';
import { ErrorModal } from '../ErrorModal/ErrorModal';
import { Loading } from '../Loading';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2, 2.5),
}));

export const SignUpForm = () => {
  const [isFormStepOne, setIsFormStepOne] = useState<boolean>(true);

  const {
    getUserProfile: { data: userProfileData },
    postUserProfile,
    patchUserProfile,
  } = useOxford();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control,
    trigger,
  } = useForm<SignUpFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(signUpSchema),
    defaultValues: {
      terms_and_conditions: false,
    },
    values: {
      terms_and_conditions: false,
      first_name: userProfileData?.first_name ?? '',
      last_name: userProfileData?.first_name ?? '',
      address: userProfileData?.address ?? '',
      city: userProfileData?.city ?? '',
      state: userProfileData?.state ?? '',
      zip: userProfileData?.zip ?? '',
      phone_number: formatPhone(userProfileData?.phone_number ?? ''),
      tokenized_dob: '',
      tokenized_ssn: '',
      tokenized_ssn_confirmation: '',
    },
  });

  const onSubmit: SubmitHandler<SignUpFormValues> = async (data) => {
    // if UserProfile is pre-existing then PUT the data.
    if (!!userProfileData) {
      patchUserProfile.mutate(data);
    } else {
      postUserProfile.mutate(data);
    }
    setIsFormStepOne(true);
  };

  const handleNext = async () => {
    const result = await trigger(
      [
        'first_name',
        'last_name',
        'address',
        'apt',
        'city',
        'state',
        'zip',
        'phone_number',
      ],
      { shouldFocus: true }
    );

    if (result) setIsFormStepOne(false);
  };

  const handleBack = () => {
    setIsFormStepOne(true);
  };

  const display = () => {
    if (postUserProfile.isLoading || patchUserProfile.isLoading) {
      return <Loading />;
    }

    if (postUserProfile.isError || patchUserProfile.isError) {
      return <ErrorModal type="generic" />;
    }

    return showForm();
  };

  const showForm = () => {
    return (
      <StyledBox>
        <Header isFormStepOne={isFormStepOne} />
        <Divider sx={{ width: '100%', marginY: [2, 2.5] }} />

        <Grid container rowGap={[2.5, 2]} sx={{}}>
          {isFormStepOne ? (
            <FormStepOne
              errors={errors}
              register={register}
              setValue={setValue}
            />
          ) : (
            <FormStepTwo
              errors={errors}
              register={register}
              setValue={setValue}
              control={control}
            />
          )}
        </Grid>

        <Divider sx={{ width: '100%', marginY: [2, 2.5] }} />

        <Navigation
          isFirstOption={isFormStepOne}
          isLastOption={!isFormStepOne}
          onBack={handleBack}
          onNext={handleNext}
          onSubmit={handleSubmit(onSubmit)}
        />
      </StyledBox>
    );
  };

  return display();
};
