import { useSearchParams } from 'react-router-dom';
import {
  ResidentResults,
  useSearchPerson,
  PersonSearchLoading,
  SerializedPropertyReport,
} from '@ltvco/refresh-lib/v1';

export function SearchResidentResults() {
  const [searchParams] = useSearchParams();
  const address = searchParams.get('address') || '';

  const SearchPersonQuery = {
    searchPersonBy: 'address',
    options: {
      personSearchParams: { address },
    },
  };

  let { data: searchPersonResult, isFetched } =
    useSearchPerson(SearchPersonQuery);
  searchPersonResult = searchPersonResult as SerializedPropertyReport;

  return (
    <>
      {!isFetched ? (
        <PersonSearchLoading />
      ) : (
        <ResidentResults
          searchAddress={address}
          residents={searchPersonResult?.properties?.[0]?.residents || []}
        />
      )}
    </>
  );
}
