import { Stack, styled } from '@ltvco/refresh-lib/theme';
import { useSessionEscalation } from '@ltvco/refresh-lib/v1';
import { Summaries } from './Summaries';
import { CheckEmail } from './CheckEmail';

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(2, 2.5),
}));

export const GettingStarted = () => {
  const { checkEmail } = useSessionEscalation();

  return (
    <StyledStack>{checkEmail ? <CheckEmail /> : <Summaries />}</StyledStack>
  );
};
