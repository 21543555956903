import { CustomIcons, useTheme } from '@ltvco/refresh-lib/theme';
import { Owner } from '@ltvco/refresh-lib/v1';
import {
  Email,
  Radar,
  Person2,
  Phone,
  Work,
  TaskAlt,
} from '@mui/icons-material';

export const getEmailNavLinkData = (
  potentialOwnersLength: number,
  owner: Owner
) => {
  return [
    {
      href: '#email-overview',
      sectionId: '#email-overview',
      text: 'Overview',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'identity',
    },
    {
      href: '#email-fraud-check-section',
      sectionId: '#email-fraud-check-section"',
      text: 'FraudScan Summary',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'fraud_check',
    },
    {
      href: '#potential-owners-section',
      sectionId: '#potential-owners-section',
      text: 'Potential Owners',
      reportItemsCount: potentialOwnersLength,
      notificationSection: 'owners',
    },
    {
      href: '#possible-photos-section',
      sectionId: '#possible-photos-section',
      text: 'Possible Photos',
      reportItemsCount: owner.images?.length || null,
      notificationSection: 'photos',
    },
    {
      href: '#phone-numbers-section',
      sectionId: '#phone-numbers-section',
      text: 'Phone Numbers',
      reportItemsCount: owner.phones.length,
      notificationSection: 'phones',
    },
    {
      href: '#email-section',
      sectionId: '#email-section',
      text: 'Emails',
      reportItemsCount: owner.emails.length,
      notificationSection: 'emails',
    },
    {
      href: '#address-history-section',
      sectionId: '#address-history-section',
      text: 'Address History',
      reportItemsCount: owner.addresses.length,
      notificationSection: 'addresses',
    },
    {
      href: '#jobs-section',
      sectionId: '#jobs-section',
      text: 'Professional Background',
      reportItemsCount: owner.jobs.length,
      notificationSection: 'jobs',
    },
    {
      href: '#education-section',
      sectionId: '#education-section',
      text: 'Education',
      reportItemsCount: owner.educations.length,
      notificationSection: 'educations',
    },
    {
      href: '#social-media-section',
      sectionId: '#social-media-section',
      text: 'Social & Websites',
      reportItemsCount: owner.profiles.length + owner.usernames.length,
      notificationSection: 'socials',
    },
    {
      href: '#possible-owned-assets-section',
      sectionId: '#possible-owned-assets-section',
      text: 'Assets',
      reportItemsCount:
        (owner?.ownedAssets?.real_estate?.length ?? 0) +
        (owner?.ownedAssets?.vehicles?.watercraft?.length ?? 0) +
        (owner?.ownedAssets?.vehicles?.aircraft?.length ?? 0) +
        (owner?.ownedAssets?.vehicles?.automobiles?.length ?? 0),
      notificationSection: 'real_estate',
    },
  ];
};

export const getEmailNavLinkDataV2 = (
  potentialOwnersLength: number,
  owner: Owner
) => {
  const theme = useTheme();

  return [
    {
      href: '#email-overview',
      labelText: 'Overview',
      icons: [
        <Email
          sx={{ color: theme.palette.info.main, fontSize: '2.5rem' }}
        />,
      ],
      name: 'email-overview',
    },
    {
      href: '#email-fraud-check-section',
      labelText: 'FraudScan Summary',
      icons: [
        <Radar
          sx={{ color: theme.palette.info.main, fontSize: '1.4rem' }}
        />,
      ],
      hideReportItemsCount: true,
      name: 'email-fraud-check-section',
    },
    {
      labelText: 'Potential Owners',
      icons: [
        <Person2
          sx={{ color: theme.palette.info.main, fontSize: '1.4rem' }}
        />,
      ],
      name: 'potential-owners-section',
      childItems: [
        {
          href: '#potential-owners-section',
          labelText: 'Potential Owners',
          reportItemsCount: potentialOwnersLength,
          name: 'potential-owners-section',
        },
        {
          href: '#possible-photos-section',
          labelText: 'Possible Photos',
          reportItemsCount: owner.images?.length || null,
          name: 'possible-photos-section',
        },
      ],
    },
    {
      labelText: 'Contact Information',
      icons: [
        <Phone
          sx={{ color: theme.palette.info.main, fontSize: '1.4rem' }}
        />,
      ],
      childItems: [
        {
          href: '#phone-numbers-section',
          labelText: 'Phone Numbers',
          reportItemsCount: Math.max(owner.phones.length - 1, 0),
          name: 'phone-numbers-section',
        },
        {
          href: '#email-section',
          labelText: 'Email Addresses',
          reportItemsCount: owner.emails.length,
          name: 'email-section',
        },
        {
          href: '#address-history-section',
          labelText: 'Address History',
          reportItemsCount: owner.addresses.length,
          name: 'address-history-section',
        },
        {
          href: '#social-media-section',
          sectionId: '#social-media-section',
          labelText: 'Social Media',
          reportItemsCount: owner.profiles.length + owner.usernames.length,
          name: 'social-media-section',
        },
      ],
    },
    {
      labelText: 'Jobs & Educations',
      icons: [
        <Work sx={{ color: theme.palette.info.main, fontSize: '1.4rem' }} />,
      ],
      name: 'jobs-educations-section',
      childItems: [
        {
          href: '#jobs-section',
          sectionId: '#jobs-section',
          labelText: 'Jobs',
          reportItemsCount: owner.jobs.length,
          name: 'jobs-section',
        },
        {
          href: '#education-section',
          sectionId: '#education-section',
          labelText: 'Education',
          reportItemsCount: owner.educations.length,
          name: 'education-section',
        },
      ],
    },
    {
      labelText: 'Assets',
      icons: [<CustomIcons.OwnedAssetsIcon color="info" fontSize="small" />],
      href: '#possible-owned-assets-section',
      name: 'assets',
      reportItemsCount:
        (owner?.ownedAssets?.real_estate?.length ?? 0) +
        (owner?.ownedAssets?.vehicles?.watercraft?.length ?? 0) +
        (owner?.ownedAssets?.vehicles?.aircraft?.length ?? 0) +
        (owner?.ownedAssets?.vehicles?.automobiles?.length ?? 0),
    },
    {
      labelText: 'Next Steps',
      icons: [
        <TaskAlt
          sx={{ color: theme.palette.info.main, fontSize: '1.4rem' }}
        />,
      ],
      name: 'next-steps',
      childItems: [
        {
          href: '#Notes-section',
          labelText: 'Notes',
          hideReportItemsCount: true,
          name: 'notes-section',
        },
        {
          href: '#related-reports-section',
          labelText: 'Related Reports',
          hideReportItemsCount: true,
          name: 'related-reports-section',
        },
        {
          href: '#report-rating',
          labelText: 'Report Rating',
          hideReportItemsCount: true,
          name: 'report-rating',
        },
      ],
    },
  ];
};
