import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Stack, Box } from '@ltvco/refresh-lib/theme';
import {
  isZeroed,
  useScrollToSectionOnNavigate,
  DateUtil,
} from '@ltvco/refresh-lib/utils';
import {
  EmailSection as EmailSectionV2,
  PhoneSection as PhoneSectionV2,
  AddressHistorySection as AddressHistorySectionV2,
  EducationSection as EducationSectionV2,
  JobsSection as JobsSectionV2,
  SocialSection as SocialSectionV2,
  ReportActionsWithDateUpdated,
  ReportRating as ReportRatingV2,
  NotesSection as NotesSectionV2,
  RelatedReportsSection as RelatedReportsSectionV2,
  AssetsSection as AssetsSectionV2,
  PotentialOwners as PotentialOwnersV2,
  ReportNavigationMenu,
  ReportNavigationMobileMenu,
  ReportNavigationMenuProps,
} from '@ltvco/refresh-lib/v2';

import type { ReportRouteProps } from './types';
import {
  getEmailNavLinkDataV2,
  getEmailNavLinkData,
} from 'navLinkData/emailNavLinkData';
import { useState, useContext, useEffect } from 'react';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import {
  DebugMenu,
  EmailFraudCheckSection,
  EmailOverview,
  Monitoring,
  ReportChangesOverview,
  ReportClaiming,
  ReportFactory,
  ReportLoading,
  ReportMonitor,
  ReportNullState,
  ReportOptions,
  useRemouladeReportSnapshot,
  useReport,
  useReportMonitors,
  useReportRedirect,
  useSession,
} from '@ltvco/refresh-lib/v1';

interface EmailReportProps extends ReportRouteProps {}

const reportType = 'email';

export function EmailReport({
  permalink,
  isMonitored = false,
}: EmailReportProps) {
  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  const { currentMonitors, reportIsMonitored } = useReportMonitors();
  const [initialIsMonitored, setInitialIsMonitored] = useState(isMonitored);

  useEffect(() => {
    if (reportIsMonitored) {
      setInitialIsMonitored(true);
    }
  }, [reportIsMonitored]);
  const monitorState = reportIsMonitored
    ? reportIsMonitored
    : initialIsMonitored;

  const coreResult = useReport(permalink, monitorState);
  const remouladeResult = useRemouladeReportSnapshot(permalink, monitorState);
  const flattenedRemouladeResult = remouladeResult
    ? { ...remouladeResult, data: remouladeResult.data?.data }
    : remouladeResult;

  const {
    session: { account },
  } = useSession();
  const { redirectToSearchContactUrl } = useReportRedirect();
  const { trackEvent } = useContext(AppConfig);

  const currentUserInfo = account?.account?.user_info;
  const queryResult = (
    initialIsMonitored ? flattenedRemouladeResult : coreResult
  ) as typeof coreResult;

  // If remoulade fails, we fall back to core. Most likely cause is race condition between creating monitor
  // and remoulade fetching data from core.
  useEffect(() => {
    if (remouladeResult.isError) {
      setInitialIsMonitored(false);
    }
  }, [remouladeResult.isError]);

  const zeroed = isZeroed(queryResult);
  const { hash, search } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: true,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };
  const [activeMonitor, setActiveMonitor] = useState(false);
  const [activeClaiming, setActiveClaiming] = useState(false);

  useEffect(() => {
    setActiveClaiming(
      currentMonitors.some((monitor: ReportMonitor) => {
        return monitor.permalink === permalink && monitor.is_claimed;
      })
    );
  }, [currentMonitors, permalink]);

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={10} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  const report = ReportFactory.create(queryResult?.data, 'email');

  if (!report) return <ReportLoading menuItems={10} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const showReportClaiming =
    currentUserInfo?.email?.toLowerCase() ===
    report.emailAddress?.toLowerCase();

  const MonitoringComponent = showReportClaiming ? (
    <ReportClaiming
      permalink={permalink}
      reportType={reportType}
      reportIsMonitoredAndClaimed={activeClaiming}
    />
  ) : (
    <Monitoring permalink={permalink} reportType={reportType} />
  );

  const owner = report.getOwner(reportOptions);
  const {
    ownerName,
    emails,
    phones,
    educations,
    jobs,
    profiles,
    usernames,
    addresses,
    ownedAssets,
    images,
    userIds,
    relatives,
  } = owner;

  const { supportingEmail } = report;

  const { fraud_score, deliverability, recent_abuse, is_leaked } =
    supportingEmail || {};

  const emailNavLinkDataV2 = getEmailNavLinkDataV2(
    report?.data.people?.length || 0,
    owner
  );

  const emailNavLinkData = getEmailNavLinkData(
    report?.data.people?.length || 0,
    owner
  );

  const queryParams = new URLSearchParams(search);
  const fallbackEmail = queryParams.get('email') || '';
  const lowerCaseEmailAddress =
    report?.emailAddress?.toLowerCase() ?? fallbackEmail;
  document.title = `${lowerCaseEmailAddress} - NeighborWho`;

  const date = new DateUtil();
  const reportUpdateDate = report.data.meta?.updated_at
    ? date.parseDateFromString(
        report.data.meta?.updated_at,
        'yyyy-MM-dd',
        'yyyy-MM-dd HH:mm:ss ZZZ'
      )
    : '';

  const handleOnBeginSearch = () => {
    trackEvent('email report promo', 'navigation click', 'contact search');
    redirectToSearchContactUrl({});
  };

  return (
    <Box>
      <Stack direction="row">
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
        >
          <ReportNavigationMenu
            reportType="Email Report"
            headerTitle={lowerCaseEmailAddress}
            menuItems={
              emailNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
            }
          />
        </Box>

        <Box marginTop={0}>
          {activeMonitor && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={emailNavLinkData}
            />
          )}

          <>
            <Box
              sx={{
                height: 30,
                backgroundColor: '#f8f8f8',
                position: 'sticky',
                marginBottom: '-10px',
                marginX: -1,
                top: 52,
                zIndex: 10,
                display: {
                  xs: 'none',
                  sm: 'block',
                  md: 'block',
                  lg: 'block',
                },
              }}
            />

            <ReportActionsWithDateUpdated
              reportType={reportType}
              reportTitle="Email"
              dateUpdated={reportUpdateDate}
              reportUpgraded={false}
            />
          </>

          <EmailOverview
            reportType={reportType}
            email={lowerCaseEmailAddress}
            showHighConfidenceToggle
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) => {
              handleSetReportOptions({
                showHighConfidenceDataOnly: value,
              });
            }}
            personName={ownerName || lowerCaseEmailAddress}
            emailOverviewItemsData={{
              ownerName,
              emails,
              jobs,
              educations,
              phones,
              profiles,
              usernames,
              userIds,
              peopleCount: report.data.people?.length,
            }}
            Monitoring={MonitoringComponent}
          />
          <EmailFraudCheckSection
            fraudScore={fraud_score}
            deliverability={deliverability}
            abusiveBehavior={recent_abuse}
            leaked={is_leaked}
            showEmailFraudScan={supportingEmail != undefined}
            emailAddress={lowerCaseEmailAddress}
            showReportCTA
          />
          <PotentialOwnersV2
            potentialOwners={report.data.people || []}
            potentialOwnerIndex={reportOptions.potentialOwnerIndex}
            setPotentialOwnerIndex={(index: number) => {
              handleSetReportOptions({ potentialOwnerIndex: index });
            }}
            personName={ownerName || lowerCaseEmailAddress}
            permalink={permalink}
          />

          <PhoneSectionV2
            personName={ownerName || lowerCaseEmailAddress}
            phoneList={phones}
            permalink={permalink}
          />
          <EmailSectionV2
            personName={ownerName || lowerCaseEmailAddress}
            emailList={emails}
            permalink={permalink}
          />
          <AddressHistorySectionV2
            addresses={addresses}
            personName={ownerName || lowerCaseEmailAddress}
            permalink={permalink}
          />
          <SocialSectionV2
            personName={ownerName || lowerCaseEmailAddress}
            profiles={profiles}
            usernames={usernames}
            permalink={permalink}
          />
          <JobsSectionV2
            personName={ownerName || lowerCaseEmailAddress}
            jobs={jobs}
            permalink={permalink}
          />
          <EducationSectionV2
            personName={ownerName || lowerCaseEmailAddress}
            educations={educations}
            permalink={permalink}
          />
          <AssetsSectionV2
            personName={ownerName || lowerCaseEmailAddress}
            ownedAssets={ownedAssets}
            permalink={permalink}
          />
          <NotesSectionV2 permalink={permalink} />
          <RelatedReportsSectionV2
            reportType="email"
            personName={ownerName || lowerCaseEmailAddress}
            potentialOwners={report?.data?.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
          />
          <ReportRatingV2
            report_type={reportType}
            rating={report.data.meta?.rating || null}
          />
        </Box>
      </Stack>
      <ReportNavigationMobileMenu
        reportType="Email Report"
        headerTitle={lowerCaseEmailAddress}
        menuItems={emailNavLinkDataV2 as ReportNavigationMenuProps['menuItems']}
      />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </Box>
  );
}
