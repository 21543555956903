import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Button, styled, Text } from '@ltvco/refresh-lib/theme';
import { ReportSection } from '@ltvco/refresh-lib/v1';
import { useNavigate } from 'react-router-dom';
import { constants } from 'appConstants';
import { useContext } from 'react';

const OwnerSearchImg = styled('img')(({ theme }) => ({
  width: '64px',
  height: '64px',
  alignSelf: 'center',
}));

export const OwnerSearchGenericCTA = () => {
  const navigate = useNavigate();
  const {
    images: { ownerSearchGenericCta },
  } = constants;
  const { routingUtils } = useContext(AppConfig);
  const { location } = routingUtils;
  const redirectToOwnerSearch = () => {
    navigate('/search/owner');
  };
  return (
    <ReportSection hasContent sectionId="owner-search-generic-cta">
      <OwnerSearchImg src={ownerSearchGenericCta} alt="Owner Search" />
      <Text variant="h3" marginTop={1} alignSelf="center">
        Search properties by owner
      </Text>
      <Text variant="body1" alignSelf="center">
        Find additional property owners and their assets
      </Text>
      <Button
        variant="contained"
        color="success"
        onClick={redirectToOwnerSearch}
        sx={{ width: 'fit-content', alignSelf: 'center' }}
      >
        Search Owner Assets
      </Button>
    </ReportSection>
  );
};
