import { CookiesStorage } from '@ltvco/refresh-lib';
import { AppConfig, AppConstants } from '@ltvco/refresh-lib/ctx';
import {
  getFirstRecordSearch,
  FirstRecordSearch,
} from '@ltvco/refresh-lib/utils';
import {
  RightRailCard,
  NeedAssistanceSidebar,
  useSnackbar,
  closeSnackbar,
  MobileAppsCtaCard,
  useAccount,
  EmailVerificationErrorModal,
  EmailVerificationSuccessModal,
  EmailVerificationInvalidModal,
  PremiumDataModal,
  usePremiumDataModal,
} from '@ltvco/refresh-lib/v1';
import {
  PremiumPaymentSuccessModal,
  ReactivatePausedAccountCta,
} from '@ltvco/refresh-lib/payments';
import {
  EmailSettingsCard,
  MonitoredReportsModal,
  SideBarMigrationCta,
} from '@ltvco/refresh-lib/v2';
import {
  Button,
  Text,
  Grid,
  Link,
  Stack,
  styled,
  CustomIcons,
} from '@ltvco/refresh-lib/theme';
import { DateUtil, useScreenSize } from '@ltvco/refresh-lib/utils';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Campaign } from '@mui/icons-material';
import { constants } from 'appConstants';
import { SidenavContainer } from '../components/sidenav/SidenavContainer';
import { useQueryParam, StringParam } from 'use-query-params';
import { checkPlanVariations } from 'utils/checkPlanVariations';

const CreditCardIcon = styled(CustomIcons.CreditCardIcon)(({ theme }) => ({
  fontSize: '3.75rem',
  padding: theme.spacing(0.9375),
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

const StyledContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(2),
  width: '100%',
  '&&': {
    '& > *': {
      width: '100%',
      maxWidth: '100%',
      border: `1px solid ${theme.palette.secondary.light}`,
      boxShadow: 'none',
      '& > button': {
        width: '95%',
      },
      '& > div button': {
        paddingRight: 0,
      },
    },
  },
}));

const VERIFICATION_EVENT_ACTION = 'view';
const VERIFICATION_EVENT_LABEL = 'email verification result modal';

export function DashboardParent({
  contactUsHandler,
  handleLogout,
}: {
  contactUsHandler: () => void;
  handleLogout: () => void;
}) {
  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const { routingUtils, trackEvent } = useContext(AppConfig);
  const cookieStorage = new CookiesStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: account } = useAccount(true);
  const [statusParam, setStatusParam] = useQueryParam('status', StringParam);
  const {
    links: { propertyAdvertiseLink },
  } = useContext(AppConstants);
  const { isMobile } = useScreenSize();

  const dateUtil = new DateUtil();
  const currentDate = new Date();

  const { isModalOpen, closeModal, triggerModal } = usePremiumDataModal();
  const [searchType, setSearchType] = useState('');
  const [isInSingleReportVariation, setIsInSingleReportVariation] =
    useState(false);
  const [paymentShowModalNumber, setPaymentShowModalNumber] =
    useState<number>(0);
  const [isSuccessModaVisible, setIsSuccessModaVisible] =
    useState<boolean>(false);

  const openSuccessModal = () => {
    setIsSuccessModaVisible(true);
  };

  const closeModals = () => {
    setIsSuccessModaVisible(false);
    setPaymentShowModalNumber(0);
  };

  const onSuccessPaymentHandler = () => {
    closeModals();
    navigate('/dashboard');
  };

  // State
  const [monitoredReportsOpen, setMonitoredReportsOpen] = useState(false);
  const [showEmailExpiredModal, setShowEmailExpiredModal] = useState(false);
  const [showEmailSuccessModal, setShowEmailSuccessModal] = useState(false);
  const [showEmailInvalidModal, setShowEmailInvalidModal] = useState(false);

  if (account?.account?.user_settings?.show_info_collection_screen) {
    navigate('/self-searching');
  }

  // LifeCycle
  useEffect(() => {
    if (!account) return;

    const firstRecordSearchData = getFirstRecordSearch();
    cookieStorage.removeValue('first_record_search');

    let navigateTo;

    const reportLimit =
      account?.account?.subscription_info?.monthly_report_limit;
    const oneReportLimit = reportLimit && reportLimit === 1;

    if (firstRecordSearchData && !oneReportLimit) {
      const typesForReportRoute = [
        'phone',
        'email',
        'username',
        'property',
        'vehicle',
      ];
      if (typesForReportRoute.includes(firstRecordSearchData.searchType)) {
        navigateTo = handleRedirectToReportRoute(firstRecordSearchData);
      }

      const typesForSearchRoute = ['person', 'for_sale', 'pre_foreclosures'];
      if (typesForSearchRoute.includes(firstRecordSearchData.searchType)) {
        navigateTo = handleRedirectToSearchRoute(firstRecordSearchData);
      }

      if (navigateTo) {
        navigate(navigateTo);

        // Setting this cookie for redirecting properly on the Onboarding Questions
        cookieStorage.setValue('first_report_navigate', navigateTo);
      }
    }
  }, [account]);

  useEffect(() => {
    if (localStorage.getItem('pausedAccountSnackbarId')) {
      try {
        closeSnackbar(
          JSON.parse(localStorage.getItem('pausedAccountSnackbarId') || '')
        );
      } catch (e) {
        console.log('error', e);
      }
    }

    handleSiteNotifications();
    handleReactivationForPausedAccountsBanner();
  }, [account]);

  useEffect(() => {
    if (!account) return;

    const isEmailVerified = account?.account.user_info?.verified_email;
    const hasShownSuccessModal =
      account?.account.user_settings?.hide_email_verification_success;

    if (
      !isEmailVerified &&
      statusParam === 'success' &&
      !hasShownSuccessModal
    ) {
      setStatusParam(undefined, 'replaceIn');
      setShowEmailSuccessModal(true);
      trackEvent(
        'email verification success',
        VERIFICATION_EVENT_ACTION,
        VERIFICATION_EVENT_LABEL
      );
    } else if (!isEmailVerified && statusParam === 'expired') {
      setStatusParam(undefined, 'replaceIn');
      setShowEmailExpiredModal(true);
      trackEvent(
        'email verification expired',
        VERIFICATION_EVENT_ACTION,
        VERIFICATION_EVENT_LABEL
      );
    } else if (!isEmailVerified && statusParam === 'invalid') {
      setStatusParam(undefined, 'replaceIn');
      setShowEmailInvalidModal(true);
      trackEvent(
        'email verification invalid',
        VERIFICATION_EVENT_ACTION,
        VERIFICATION_EVENT_LABEL
      );
    } else if (!isEmailVerified) {
      // setShowEmailPromptModal(true);
    }
  }, [account, statusParam, setStatusParam]);

  useEffect(() => {
    if (!account) return;
    const planName =
      account.account.subscription_info.subscription_plan_unique_name;
    const { isInSingleReportVariation } = checkPlanVariations(planName);
    setIsInSingleReportVariation(isInSingleReportVariation);
  }, [account]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isFallFromCTAEmail = urlParams.get('premiumData');

    if (isInSingleReportVariation && isFallFromCTAEmail === 'true') {
      triggerModal(true, isInSingleReportVariation);
    }
  }, [isInSingleReportVariation]);

  const featureAction = (str: string) => {
    setSearchType(str);
    triggerModal(true, isInSingleReportVariation);
  };

  // Functions
  function closeMonitoredReportsModal() {
    setMonitoredReportsOpen(false);
  }

  function handleRedirectToSearchRoute(
    firstRecordSearchData: FirstRecordSearch
  ) {
    let navigateRoute;

    if (firstRecordSearchData.searchType === 'person') {
      navigateRoute = routingUtils.searchPersonUrl({
        fName: firstRecordSearchData.first_name,
        mName: firstRecordSearchData.middle_name,
        lName: firstRecordSearchData.last_name,
        state: firstRecordSearchData.state,
        city: firstRecordSearchData.city,
        age: firstRecordSearchData.age,
      });
    } else if (
      firstRecordSearchData.searchType === 'for_sale' ||
      firstRecordSearchData.searchType === 'pre_foreclosures'
    ) {
      navigateRoute = routingUtils.searchPropertyListingsUrl({
        city: firstRecordSearchData.city,
        state: firstRecordSearchData.state,
        zip5: firstRecordSearchData.zip_code,
        search_type:
          firstRecordSearchData?.searchType === 'pre_foreclosures'
            ? 'pre_foreclosures'
            : undefined,
      });
    }

    return navigateRoute;
  }

  function handleRedirectToReportRoute(
    firstRecordSearchData: FirstRecordSearch
  ) {
    let queryParams = '';

    if (firstRecordSearchData.searchType === 'phone') {
      queryParams = `phone=${firstRecordSearchData.phone_number}`;
    }
    if (firstRecordSearchData.searchType === 'email') {
      queryParams = `email=${firstRecordSearchData.email}`;
    }
    if (firstRecordSearchData.searchType === 'username') {
      queryParams = `username=${firstRecordSearchData.username}`;
    }
    if (firstRecordSearchData.searchType === 'property') {
      queryParams = `address=${firstRecordSearchData.address}&city=${firstRecordSearchData.city}&state=${firstRecordSearchData.state}&zipcode=${firstRecordSearchData.zip_code}`;
    }
    if (firstRecordSearchData.searchType === 'vehicle') {
      if (firstRecordSearchData.license_plate && firstRecordSearchData.state) {
        queryParams = `license_plate=${firstRecordSearchData.license_plate}&state=${firstRecordSearchData.state}`;
      }
      if (firstRecordSearchData.vin) {
        queryParams = `vin=${firstRecordSearchData.vin}`;
      }
      if (
        firstRecordSearchData.make &&
        firstRecordSearchData.model &&
        firstRecordSearchData.year
      ) {
        queryParams = `make=${firstRecordSearchData.make}&model=${firstRecordSearchData.model}&year=${firstRecordSearchData.year}`;
      }
    }

    return `/report/${firstRecordSearchData.searchType}?${queryParams}`;
  }

  function showMobileAppCtas() {
    return [
      '/dashboard/reports',
      '/dashboard/lists',
      '/dashboard/monitoring',
      '/dashboard/billing',
    ].includes(location.pathname);
  }

  function handleReactivationForPausedAccountsBanner() {
    if (!account) return;

    const isInPausedStatus = account?.meta?.subscription_state === 'paused';
    if (!isInPausedStatus) return;

    const today = currentDate.toISOString();
    const expirationDate = account?.account.subscription_info?.normalize_date;

    if (!expirationDate) return;

    const isAfterCurrentDate = dateUtil.isAfter(today, expirationDate);

    const expiredMessage = (
      <Text>
        <b>
          Your account expired on{' '}
          {dateUtil.parseDateFromString(
            account?.account.subscription_info?.normalize_date,
            'M/d/y'
          )}{' '}
          . Reactivate to regain access.
        </b>
      </Text>
    );

    const cancelledMessage = (
      <Text>
        <b>
          Your account with member ID {account?.account.user_info.user_code} has
          been cancelled.
        </b>{' '}
        <br /> You have until{' '}
        {dateUtil.parseDateFromString(
          account?.account.subscription_info?.normalize_date,
          'M/d/y'
        )}{' '}
        to continue running reports. Please reactivate now to maintain access.
        Visit{' '}
        <Link target="_blank" href={constants.links.reactivateLink}>
          here
        </Link>{' '}
        to reactivate.
      </Text>
    );

    const message = isAfterCurrentDate ? expiredMessage : cancelledMessage;

    const pausedAccountSnackbarId = enqueueSnackbar(message, {
      variant: 'info',
      persist: true,
      action: getCloseButton(() => {
        closeSnackbar(pausedAccountSnackbarId);
      }),
    });

    localStorage.setItem(
      'pausedAccountSnackbarId',
      JSON.stringify(pausedAccountSnackbarId)
    );
  }

  function getCloseButton(onClick: () => void) {
    return (
      <Button
        onClick={onClick}
        variant="contained"
        sx={(theme) => ({
          fontsize: theme.spacing(0.75),
          maxHeight: '40px',
          width: '125px',
          color: `${theme.palette.success.contrastText} !important`,
          backgroundColor: `${theme.palette.success.main} !important`,
        })}
      >
        Close
      </Button>
    );
  }

  function handleSiteNotifications() {
    if (!account) return;
    const siteNotification = account?.meta?.notification;
    if (!siteNotification) return;
    const siteNotificationSnackbarId = enqueueSnackbar(
      <Text>
        <b>{siteNotification.title}</b> <br /> {siteNotification.message}
      </Text>,
      {
        variant: 'info',
        persist: true,
        action: (
          <Button
            onClick={() => {
              closeSnackbar(siteNotificationSnackbarId);
            }}
            variant="contained"
            sx={(theme) => ({
              fontsize: theme.spacing(0.75),
              maxHeight: '40px',
              width: '125px',
              color: `${theme.palette.success.contrastText} !important`,
              backgroundColor: `${theme.palette.success.main} !important`,
            })}
          >
            Close
          </Button>
        ),
      }
    );
  }

  const neighborhoodReportLocation = location.pathname.includes(
    '/dashboard/neighborhood-report'
  );
  const isIdentityHub = location.pathname.includes('/dashboard/identity-hub');
  const isDarkWebPage = location.pathname.includes('/dashboard/dark-web');
  const isDashboard = location.pathname === '/dashboard';
  const isRewards = location.pathname.includes('/dashboard/rewards');
  const isImportList = location.pathname.includes('/dashboard/import-list');
  const isLists = location.pathname.includes('/dashboard/lists');
  const isIdMonitor = location.pathname.includes('/dashboard/id-monitor');
  const isFraud = location.pathname.includes('/dashboard/fraud');
  const isMonitoringPage = location.pathname.includes('/dashboard/monitoring');
  const isBillingPage = location.pathname.includes('/dashboard/billing');
  const isReportsPage = location.pathname.includes('/dashboard/reports');

  const showRightRail =
    !isDashboard &&
    !isRewards &&
    !neighborhoodReportLocation &&
    !isImportList &&
    !isLists &&
    !isIdMonitor &&
    !isDarkWebPage &&
    !isIdentityHub &&
    !isFraud;

  const RightRailComponent = (
    <Grid
      item
      container
      xs={12}
      md={3}
      lg={3}
      spacing={1}
      direction="column"
      marginTop={2}
      maxWidth={{ xs: '100%', md: '275px' }}
    >
      {isBillingPage && (
        <Grid
          item
          width={'100%'}
          order={{ xs: 6, md: 1 }}
          className="needs-assistance"
        >
          <NeedAssistanceSidebar
            logout={handleLogout}
            supportImgUrl={constants.images.img24hr}
            contactUsHandler={contactUsHandler}
          />
        </Grid>
      )}
      {isMonitoringPage && (
        <Grid item width={'100%'} order={2}>
          <EmailSettingsCard
            currentInstantAlertsSetting={
              account?.account?.alert_me.settings.instant_enabled || false
            }
            currentDigestAlertsSetting={
              account?.account?.alert_me.settings.digest_enabled || false
            }
            handleMonitoredReports={() => setMonitoredReportsOpen(true)}
          />
          <MonitoredReportsModal
            isOpen={monitoredReportsOpen}
            onClose={() => closeMonitoredReportsModal()}
          />
        </Grid>
      )}
      {!isIdentityHub && (
        <>
          <Grid item width={'100%'} order={4}>
            <RightRailCard
              icon={<CreditCardIcon />}
              title="Dark Web Scan"
              subtitle="Millions of online accounts are compromised each year"
              body="Search using your email address to see if you may have been compromised on the dark web"
              buttonText="Scan Now"
              tagText="New"
              showTag={true}
              tagOnLeft={false}
              onButtonClick={() =>
                isInSingleReportVariation
                  ? featureAction('dark web')
                  : navigate('dark-web')
              }
            />
          </Grid>
          {isReportsPage && isMobile && (
            <Grid item width={'100%'} order={2} key="mobile-migration-card">
              <StyledContainer>
                <SideBarMigrationCta />
              </StyledContainer>
            </Grid>
          )}
        </>
      )}
      {showMobileAppCtas() && (
        <Grid item width={'100%'} order={5}>
          <MobileAppsCtaCard />
        </Grid>
      )}
      {account?.meta?.subscription_state === 'paused' && (
        <Grid item order={5}>
          <ReactivatePausedAccountCta />
        </Grid>
      )}
      {propertyAdvertiseLink && (
        <Grid item order={6}>
          <StyledStack direction="row" alignItems="center" spacing={0.5}>
            <Campaign />
            <Text>Advertise with us.</Text>
            <Link
              href={propertyAdvertiseLink}
              target="_blank"
              marginLeft={0.5}
              color="secondary.contrastText"
            >
              Learn more
            </Link>
          </StyledStack>
        </Grid>
      )}
      <PremiumDataModal
        isOpen={isModalOpen}
        onClose={closeModal}
        searchType={searchType}
        paymentShowModalNumber={paymentShowModalNumber}
        setPaymentShowModalNumber={setPaymentShowModalNumber}
        openSuccessModal={openSuccessModal}
        shouldFireGAUpsell
      />
      <PremiumPaymentSuccessModal
        open={isSuccessModaVisible}
        onClose={closeModals}
        onAccept={onSuccessPaymentHandler}
      />
    </Grid>
  );

  const RightRail = showRightRail ? RightRailComponent : <></>;

  return (
    <SidenavContainer>
      <EmailVerificationErrorModal
        open={showEmailExpiredModal}
        onClose={() => setShowEmailExpiredModal(false)}
      />
      <EmailVerificationSuccessModal
        open={showEmailSuccessModal}
        onClose={() => setShowEmailSuccessModal(false)}
      />
      <EmailVerificationInvalidModal
        open={showEmailInvalidModal}
        onClose={() => setShowEmailInvalidModal(false)}
      />
      <Grid container justifyContent={'space-between'}>
        <Grid
          item
          xs={12}
          md={showRightRail ? 8 : 12}
          lg={showRightRail ? 8 : 12}
          maxWidth={{
            xs: '100%',
            md: showRightRail ? '911px !important' : '100%',
          }}
        >
          <Outlet />
        </Grid>
        {RightRail}
      </Grid>
    </SidenavContainer>
  );
}
