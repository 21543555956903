import { AppConstants } from '@ltvco/refresh-lib/ctx';
import { Avatar, Text, Grid, Button, Link } from '@ltvco/refresh-lib/theme';
import warning from '../images/img_warning.svg';
import { useNavigate } from 'react-router-dom';
import { SidenavContainer } from 'components/sidenav/SidenavContainer';
import { useContext } from 'react';
import { useSession } from '@ltvco/refresh-lib/v1';
import { goToLogin } from 'utils/goToLogin';

export function ErrorPage() {
  const navigate = useNavigate();
  const {
    data: { supportEmail },
  } = useContext(AppConstants);
  const { isAuthenticated } = useSession().session;

  function goToDashboard() {
    navigate('/dashboard');
  }

  const Container = isAuthenticated ? SidenavContainer : Grid;
  const navigateFunction = isAuthenticated
    ? goToDashboard
    : () => goToLogin('https://www.neighborwho.com');
  const navigateTo = isAuthenticated ? 'dashboard' : 'login';

  return (
    <Container>
      <Grid container spacing={3} textAlign={'center'} marginTop={8}>
        <Grid item xs={12}>
          <Avatar
            src={warning}
            alt="404"
            sx={{ width: 95, height: 80, margin: 'auto' }}
            variant="square"
          />
          <Text variant="h2" color={'primary'} fontSize={52} marginTop={2.5}>
            Whoops!
          </Text>
          <Text variant="h3" fontSize={24} marginTop={2.5}>
            Looks like something went wrong.
          </Text>
          <Text margin={'auto'}>
            We apologize for the inconvenience; please contact{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> if this
            problem persists.
          </Text>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 2.5 }}
            onClick={navigateFunction}
          >
            Go to {navigateTo}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
