import { useSearchParams } from 'react-router-dom';
import { SearchResidentResults } from './SearchResidentResults';
import { SearchPersonResults } from './SearchPersonResults';

export function SearchPersonResultsContainer() {
  const [searchParams] = useSearchParams();

  if (searchParams.get('address')) {
    return <SearchResidentResults />;
  }

  return <SearchPersonResults />;
}
