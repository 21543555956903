import { useAccountListsV2, AccountListsV2 } from '@ltvco/refresh-lib/v1';
import type { LizanoListInfo } from '@ltvco/refresh-lib/v1';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function Lists() {
  const navigate = useNavigate();
  const { data: lists, isLoading } = useAccountListsV2();
  const [searchParams, setSearchParams] = useSearchParams();
  const [redirectToSpecificList, setRedirectToSpecificList] = useState(true);

  useEffect(() => {
    document.title = 'Report Lists - NeighborWho';
  }, []);

  const checkIfNeedToRedirectToSpecificList = (lists: LizanoListInfo[]) => {
    const listCode = searchParams.get('code');
    if (listCode) {
      const list = lists.find((list) => list.list_code === listCode);
      if (list) {
        setRedirectToSpecificList(true);
        setSearchParams((searchParams: URLSearchParams) => {
          searchParams.delete('code');
          return searchParams;
        });
        navigate(`/dashboard/lists/${list.name}`);
      } else {
        setRedirectToSpecificList(false);
      }
    } else {
      setRedirectToSpecificList(false);
    }
  };

  useEffect(() => {
    checkIfNeedToRedirectToSpecificList(lists || []);
  }, [lists, searchParams]);

  return isLoading || redirectToSpecificList ? (
    <></>
  ) : (
    <AccountListsV2 lists={lists || []} isLoading={isLoading} />
  );
}
