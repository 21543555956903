import { Box, Stack, Text, Divider, Button } from '@ltvco/refresh-lib/theme';
import { isZeroed, formatPhone } from '@ltvco/refresh-lib/utils';
import { getFraudSearchSubtitleText } from './utils';
import {
  SearchWrapper,
  SearchFormsContainer,
  DetailsContainer,
  ReportContainer,
} from './components';
import { alpha } from '@mui/material';
import type { ReportRouteProps } from '../types';
import React, { useRef } from 'react';
import {
  CommentsSectionV2,
  DebugMenu,
  FraudPhoneLocationDetails,
  FraudPhoneOverview,
  FraudPhoneThreatDetection,
  FraudSearchContainer,
  PhoneFraudReport,
  PotentialOwnerCta,
  ReportLoading,
  ReportNullState,
  SearchFormProps,
  useReport,
} from '@ltvco/refresh-lib/v1';

interface PhoneFraudReportPageProps extends ReportRouteProps {
  /** Callback to handle successful search form submission */
  onSearchSuccess: SearchFormProps['onSuccess'];
  /** Callback to handle search form submission errors */
  onSearchError: SearchFormProps['onError'];
}

export function PhoneFraudReportPage({
  permalink,
  onSearchSuccess,
  onSearchError,
}: PhoneFraudReportPageProps) {
  // hooks
  const queryResult = useReport(permalink);
  const zeroed = isZeroed(queryResult);

  // refs
  const commentsSectionRef = useRef<HTMLDivElement | null>(null);

  // state
  const [tabState, setTabState] = React.useState<string>('phone');

  // constants
  const urlParams = new URLSearchParams(window.location.search);
  const fallbackNumber = urlParams.get('phone') || '';

  const scrollToComments = () => {
    if (!commentsSectionRef.current) return;
    commentsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={2} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }
  const report = new PhoneFraudReport(queryResult?.data);

  if (!report) return <ReportLoading menuItems={2} />;
  if (report?.data?.isEmpty) {
    return <ReportNullState />;
  }

  const {
    carrier,
    fraudScore,
    isActive,
    isDoNotCall,
    isLeaked,
    isPrepaid,
    isRecentAbuse,
    isRisky,
    isSpammer,
    isVOIP,
    isValid,
    lineType,
    location,
    userActivity,
    activeStatus,
    number,
  } = report.data;

  const phoneNumber = number ?? fallbackNumber;
  document.title = `${formatPhone(phoneNumber)} - BeenVerified`;

  const timeZone = location?.timezone;

  return (
    <>
      <SearchWrapper>
        <SearchFormsContainer>
          <Text data-cy="fraud-check-form-title" variant="h2" color="success">
            FraudScan
          </Text>
          <Text
            mb={(theme) => ({ xs: theme.spacing(1), md: theme.spacing(3) })}
            data-cy="fraud-check-form-subtitle"
            variant="body2"
          >
            {getFraudSearchSubtitleText(tabState)}
          </Text>
          <FraudSearchContainer
            excludeTabs={['ip', 'url']}
            initialTab={'phone'}
            onSearchSuccess={onSearchSuccess}
            onSearchError={onSearchError}
            onSearchChange={setTabState}
          />
        </SearchFormsContainer>
      </SearchWrapper>
      <ReportContainer
        sx={{ width: 'fit-content' }}
        container
        direction={'row'}
        columns={12}
      >
        <DetailsContainer mt={3}>
          <Stack
            mb={1.5}
            flexDirection={{ sm: 'row' }}
            justifyContent={{ sm: 'space-between' }}
          >
            <Text data-cy="fraud-check-form-title" variant="h3" color="success">
              Phone Number Verification
            </Text>
            <Button
              color="success"
              sx={(theme) => ({
                paddingLeft: { xs: 0, md: theme.spacing(1) },
                width: 'fit-content',
              })}
              onClick={() => scrollToComments()}
              variant="text"
            >
              <Text
                mb={{ xs: 2.5, sm: 0 }}
                sx={{ textDecoration: 'underline' }}
                variant="body2"
              >
                Report Number
              </Text>
            </Button>
          </Stack>
          <Box
            padding={1}
            mb={4}
            sx={(theme) => ({
              backgroundColor: alpha(theme.palette.success.dark, 0.04),
            })}
          >
            <Text variant="h5" component="p" fontWeight={700} m={0}>
              Results for: {formatPhone(phoneNumber)}
            </Text>
          </Box>
          <FraudPhoneOverview
            carrier={carrier}
            isVOIP={isVOIP}
            userActivity={userActivity}
            lineType={lineType}
            isPrepaid={isPrepaid}
            isLeaked={isLeaked}
            activeStatus={activeStatus}
            isValid={isValid}
            isActive={isActive}
            isDoNotCall={isDoNotCall}
          />
          <Box mt={4}>
            <PotentialOwnerCta number={phoneNumber} reportType="phone" />
          </Box>
          <Box mt={4} mb={4}>
            <FraudPhoneThreatDetection
              fraudScore={fraudScore}
              isRisky={isRisky}
              isSpammer={isSpammer}
              isRecentAbuse={isRecentAbuse}
            />
          </Box>
          <Divider />
          <Box mt={4} mb={4}>
            <FraudPhoneLocationDetails
              phoneNumber={phoneNumber}
              timeZone={timeZone}
            />
          </Box>
          <Box ref={commentsSectionRef} mt={4} mb={4}>
            <Divider />
          </Box>
          <CommentsSectionV2 permalink={permalink} reportData={report.data} />
        </DetailsContainer>
      </ReportContainer>
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}
