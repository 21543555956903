import { Dispatch, SetStateAction, useContext } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { constants } from 'appConstants';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { publish } from '@ltvco/refresh-lib';
import { useSession } from '@ltvco/refresh-lib/v1';

export const AccountInformationOptions = [
  {
    name: 'Edit Name',
    display: 'Edit Name',
    value: 'Edit Name',
    constantValue: 'EDIT_NAME',
  },
  {
    name: 'Edit Email',
    display: 'Edit Email',
    value: 'Edit Email',
    constantValue: 'EDIT_EMAIL',
  },
  {
    name: 'Add Phone Number',
    display: 'Edit Phone Number',
    value: 'Add Phone Number',
    constantValue: 'ADD_PHONE_NUMBER',
  },
  {
    name: 'Change Password',
    display: 'Change Password',
    value: 'Change Password',
    constantValue: 'CHANGE_PASSWORD',
  },
  {
    name: 'Edit Payment Info',
    display: 'Edit Payment Info',
    value: 'Edit Payment Info',
    constantValue: 'EDIT_PAYMENT_INFO',
  },
];

export const editOptionClicked = (
  editing: string | null,
  openModal: (open: boolean) => void,
  setEditing: Dispatch<SetStateAction<string | null>>
) => {
  if (editing === 'Edit Payment Info') {
    openModal(true);
  } else {
    setEditing(
      AccountInformationOptions.find((option) => option.name === editing)
        ?.constantValue || editing
    );
  }
};

export const GenericCtaCardProps = (
  navigate: NavigateFunction,
  isInVariation: boolean,
  onVariationCtaClick: (text: string) => void
) => {
  // has_sso_connections
  const {
    session: { account },
  } = useSession();
  const { trackEventGA4 } = useContext(AppConfig);

  const has_sso_connections = account?.account?.user_info?.has_sso_connections;
  // AB Tests
  const isInSingleSignVariation = useFeatureIsOn('RTRN-68');

  const handleCtaClick = (
    ctaLabel: string,
    actionParam: string,
    eventSite: string,
    isPublish: boolean
  ) => {
    trackEventGA4({
      event_name: 'navigation_click',
      type: eventSite,
      interacted_from: 'my_account',
    });

    isInVariation
      ? onVariationCtaClick(ctaLabel)
      : isPublish
      ? publish(actionParam)
      : navigate(actionParam);
  };

  const singleSignCta = {
    headerText: 'Connect or Edit Login Options',
    bodyText:
      'Add your Google or Apple Login on top of your account password & email',
    buttonText: 'Edit Login Options',
    imgUrl: constants.images.imgSingleSignOnCta,
    onCtaClick: () =>
      window.open('https://www.neighborwho.com/sso_connections', '_blank'),
    dataCy: 'login_options_cta',
  };

  const props = [
    {
      headerText: 'Search property reports',
      bodyText: 'Search an address to find out home value, ownership and more',
      buttonText: 'Search an address now',
      imgUrl: constants.images.imgAddressGeneric,
      onCtaClick: () =>
        handleCtaClick(
          'property report',
          'openPropertySearch',
          'property',
          true
        ),
    },
    {
      headerText: 'Search background reports',
      bodyText: 'Find arrest records, contact information and more',
      buttonText: 'Search by name now',
      imgUrl: constants.images.imgSpecializedBackgroundCheck,

      onCtaClick: () =>
        handleCtaClick(
          ' background report',
          'openPersonSearch',
          'people',
          true
        ),
    },
    //TODO: Uncomment when these features are ready
    // {
    //   headerText: 'Monitor 10 different reports',
    //   bodyText: 'Get alerted about new data on reports as we become aware',
    //   buttonText: 'See more',
    //   imgUrl: constants.imgBell,

    //   onCtaClick: () => navigate('/dashboard/alertme'),
    // },
    // {
    //   headerText: 'Monitor your online identity',
    //   bodyText:
    //     'Monitor your online identity to get notified if any changes are detected',
    //   buttonText: 'Start monitoring now',
    //   imgUrl: constants.imgIdHub,
    //   onCtaClick: () => navigate('/dashboard/identity-hub'),
    // },
    {
      headerText: 'Dark Web Scan',
      bodyText:
        'See if the email you signed up with may have been leaked in a data breach',
      buttonText: 'Scan now',
      imgUrl: constants.images.imgDarkWebScan,
      onCtaClick: () =>
        handleCtaClick('dark web', '/dashboard/dark-web', 'dark_web', false),
      dataCy: 'dark_web_cta',
    },
  ];

  if (has_sso_connections || isInSingleSignVariation) {
    props.unshift(singleSignCta);
  }

  return { ctaCardProps: props, length: props.length };
};
