import { createTheme } from '@ltvco/refresh-lib/theme';

export const theme = createTheme({
  typography: {
    button: { textTransform: 'none', fontSize: '1rem', fontWeight: 'bold' },
    fontSize: 16,
    htmlFontSize: 16,
    fontFamily: "'Nunito Sans', sans-serif",
    h1: {
      fontSize: '1.875rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    caption: {
      letterSpacing: 0,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.2,
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          backgroundColor: '#fff',
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: '#fff',
          },
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: '#F8F8F8',
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          // padding: 0,
          // px: 14,
          // py: 12,
          borderRadius: 32,

          '&.smart-insights-button': {
            backgroundImage:
              'linear-gradient(to right, rgba(99,138,58,1) 0%, rgba(129,180,76,1))',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: (props) => ({
          '&.MuiSvgIcon-colorPrimary': {
            color: props.theme.palette.info.main,
          },
          '&.unrecorded-item': {
            color: '#638A3A',
          },
          '&.hidden-section-icon': {
            color: '#4a3b8f',
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#E2E2E2',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            padding: 12,
          },
          div: {
            height: 40,
            backgroundColor: '#fff',
          },
          '& .MuiFormLabel-root': {
            top: -8,
          },
          '& .Mui-focused': {
            top: 0,
          },
          '& .MuiFormLabel-filled': {
            top: 0,
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: () => ({
          '&.smart-insights-badge': {
            backgroundImage: `linear-gradient(to right, #638A3A 0%, #81B44C)`,
          },
        }),
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: () => ({
          backgroundColor: '#E6E7E9 !important',
          '&.dark-mode': {
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: (props) => ({
          color: '#000',

          '&.MuiChip-filled.MuiChip-filledInfo': {
            color: props.theme.palette.primary.contrastText,
          },
        }),
      },
    },
  },
  palette: {
    divider: '#E6E7E9',
    primary: {
      main: '#0073e6',
      light: '#E6EEF7',
      dark: '#005CB8',
      contrastText: '#fff',
    },
    info: {
      main: '#1F2F64',
      light: '#E3F0FC',
      dark: '#E3F0FC',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E7EBF0',
      light: '#ededed',
      dark: '#8b88aa',
      contrastText: '#535353',
    },
    success: {
      light: '#f8f8f8',
      main: '#0073e6', // CTA links and buttons
      dark: '#005CB8',
      contrastText: '#fff',
    },
    error: {
      light: '#dc00151f',
      main: '#DC0015',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#f8f8f8',
      action: '#EEF0F3',
    },
    text: {
      primary: '#0A0B0D',
      secondary: '#5B616E',
      disabled: '#929AAB',
      contrastText: '#fff',
    },
    barChart: {
      main: '#1F2F64',
      light: '#0073E6',
      dark: '#E2E2E2',
      contrastText: '#000',
    },
    lineChart: {
      main: '#1F2F64',
      light: '#FCDC6E',
      dark: '#0073E6',
      contrastText: '#000',
    },
    maps: {
      main: '#1F2E64',
      light: '#3388FF',
      contrastText: '#FFF',
    },
    notistackToast: {
      // Used for Success variant in Notistack alerts
      main: '#008000', // Text color
      light: '#DEFCE6', // BG color
      dark: '#005000', // Button text color
      contrastText: '#000',
    },
    neighborhoodWatch: {
      main: '#1F2F64',
      light: '#1F2F64',
      dark: '#1F2F64',
      contrastText: '#fff',
    },
    marketInsights: {
      main: '#1F2F64',
      light: '#E7EBF0',
      dark: '#1F2F64',
      contrastText: '#fff',
    },
    progress: {
      main: '#0073E6',
      light: '#1F2F64',
      dark: '#FCDC6E',
      contrastText: '#E2E2E2',
    },
    premium: {
      main: '#FCC41C',
    },
    // TODO: replace with correct colors (PFC, For rent, ...)
    listingPins: {
      main: '#EC413D',
      light: '#4AA351',
      dark: '#4A3B8F',
      contrastText: '#81b44c',
    },
    listingPinsVisited: {
      main: '#f8a09e',
      light: '#9FCBA2',
      dark: '#9F97C1',
    },
    parcelBoundaries: {
      main: '#FFFFFF',
      dark: '#000000',
    },
    aiChip: {
      main: 'linear-gradient(90deg, #9A5EE9 -6.12%, #B47BFD 29.05%, #0073E6 109.18%)',
      dark: '#B47BFD',
      light: '#0073E6',
      contrastText: '#fff',
    },
    propertySmartinsights: {
      main: 'linear-gradient(50deg, rgba(0,0,0,1) 14%, rgba(31,47,100,1) 52%, rgba(0,115,230,1) 100%)',
    },
    upgradeCheck: {
      main: '#74ad47',
    },
    reportSectionSupertitleBackgroundColor: {
      main: '#4a3b8f',
    },
    threatDetection: {
      main: '#DC0015',
      light: '#E1FAEA',
      dark: '#81B44C',
      contrastText: '#FFF1F1',
    },
    labelsAndLinks: {
      main: '#757575',
    },
  },
});
