import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { ValidReportTypes } from '@ltvco/refresh-lib/utils';
import { nameize } from '@ltvco/refresh-lib/utils';
import { Stack, Text, Box } from '@ltvco/refresh-lib/theme';
import {
  mapReportTypesToReportParams,
  useSriracha,
  Notification,
} from '@ltvco/refresh-lib/v1';
import { ReportCard, useHandleRelatedReportClick } from '@ltvco/refresh-lib/v2';
import { useContext } from 'react';

export function DashboardSuggestedReports() {
  const { data: notifications } = useSriracha();
  const handleRelatedReportClick = useHandleRelatedReportClick();
  const showSuggestedReports = useFeatureIsOn('RFRSH-2487');
  const { trackEventGA4 } = useContext(AppConfig);

  function getSuggestedReports() {
    const reports = notifications
      ?.filter(
        (notification) => notification.notification_type === 'suggested_report'
      )
      .map((notification) => {
        return formatReport(notification);
      });

    return reports || [];
  }

  function getTitle(report: {
    owner?: any;
    report_type?: any;
    query_params?: any;
  }) {
    const { query_params } = report;
    if (report.report_type === 'detailed_person_report')
      return `${report.owner}${
        query_params.age ? `, ${query_params.age}` : ''
      }`;
    if (report.report_type === 'property_report')
      return query_params.address || report.owner;
    return report.owner;
  }

  function getSubTitle(report: {
    owner?: any;
    report_type?: any;
    query_params?: any;
  }) {
    const { query_params } = report;
    if (
      report.report_type === 'detailed_person_report' ||
      report.report_type === 'property_report'
    )
      return `${query_params.city}${
        query_params.state && query_params.city ? `, ${query_params.state}` : ''
      }`;
    return '';
  }

  function formatReport(report: Notification) {
    const reportType =
      mapReportTypesToReportParams[
        report.data.report_type as keyof typeof mapReportTypesToReportParams
      ];
    return {
      ...report,
      reportType: reportType,
      data: {
        title: getTitle(report.data),
        subTitle: getSubTitle(report.data),
        query_params: report.data.query_params,
        reportType: reportType,
      },
    };
  }

  const suggestedReports = showSuggestedReports ? getSuggestedReports() : [];

  if (!showSuggestedReports || !suggestedReports.length) return null;

  return (
    <>
      <Stack
        display="flex"
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Text variant="h3" margin={0}>
          Suggested Reports
        </Text>
      </Stack>
      <Stack
        direction="row"
        columnGap={1}
        sx={{
          width: '100%',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        {suggestedReports.slice(0, 5).map((report, index) => {
          const { title, reportType, subTitle } = report.data;
          return (
            <Box
              key={`${title}-${index}`}
              sx={{
                minWidth: 'calc(100% / 4.5)',
                flexShrink: 0,
              }}
            >
              <ReportCard
                title={title}
                description={
                  <>
                    {subTitle && <Text>{subTitle}</Text>}
                    {reportType && <Text>{nameize(reportType)} Report</Text>}
                  </>
                }
                reportType={reportType as ValidReportTypes}
                clickAction={() => {
                  trackEventGA4({
                    event_name: 'feature_engagement',
                    type: 'suggested_reports',
                    interacted_from: 'home',
                  });
                  handleRelatedReportClick(report);
                }}
                actionPosition="hidden"
                containerStyles={{ minHeight: 100 }}
              />
            </Box>
          );
        })}
      </Stack>
    </>
  );
}
