import React from 'react';
import {
  AccountListsListItem,
  useAccountListsV2,
  LizanoListInfo,
} from '@ltvco/refresh-lib/v1';
import { useParams } from 'react-router-dom';

export function ListsItem() {
  const { data, isLoading } = useAccountListsV2();
  const { listName } = useParams<{ listName: string }>();

  if (isLoading) {
    return null;
  }

  const list: LizanoListInfo | undefined = data?.find(
    ({ name }: { name: string }) => name === listName
  ) as LizanoListInfo;

  return <AccountListsListItem list={list} />;
}
