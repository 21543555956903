import { AppConstants } from '@ltvco/refresh-lib/ctx';
import { SideBarMigrationCta, SideNavigationMenu } from '@ltvco/refresh-lib/v2';
import { Box } from '@ltvco/refresh-lib/theme';
import { useContext } from 'react';

type ValidRoute = 'report' | 'dashboard';

export function Sidenav({ onReport }: { onReport?: boolean }) {
  const {
    config: { sideMenuItems },
  } = useContext(AppConstants);
  const isDashboardPage = /\/dashboard\/?$/.test(location.pathname);

  const newDesignFilteredSideMenuItems = sideMenuItems.filter(
    (item) => !(item.name === 'logout')
  );

  let hiddenItems = [
    { name: 'myAccount', routesToHide: ['report'] as ValidRoute[] },
  ];

  return (
    <Box
      sx={{
        display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
        '>.MuiList-root': {
          width: '310px',
          maxWidth: '310px',
        },
      }}
    >
      <SideNavigationMenu
        menuItems={newDesignFilteredSideMenuItems}
        matchingRoute={location.pathname}
        onReport={onReport}
        hideItems={hiddenItems}
        location="sidebar"
      />
      {isDashboardPage && <SideBarMigrationCta />}
    </Box>
  );
}
