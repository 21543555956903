import { useLocation } from 'react-router-dom';
import { alpha } from '@mui/material';
import { ReportRouteProps } from 'components/reports/types';

import { useState } from 'react';
import {
  DebugMenu,
  EmailFraudReport,
  FraudEmailDetails,
  FraudEmailOverview,
  FraudEmailThreatDetection,
  FraudSearchContainer,
  ReportLoading,
  ReportNullState,
  SearchFormProps,
  useReport,
} from '@ltvco/refresh-lib/v1';
import {
  isZeroed,
  useScrollToSectionOnNavigate,
} from '@ltvco/refresh-lib/utils';
import { Text, Box, Grid, Divider } from '@ltvco/refresh-lib/theme';
import {
  DetailsContainer,
  ReportContainer,
  SearchFormsContainer,
} from './components';
import { getFraudSearchSubtitleText } from './utils';

interface EmailFraudReportPageProps extends ReportRouteProps {
  /** Callback to handle successful search form submission */
  onSearchSuccess: SearchFormProps['onSuccess'];
  /** Callback to handle search form submission errors */
  onSearchError: SearchFormProps['onError'];
}
export function EmailFraudReportPage({
  permalink,
  onSearchSuccess,
  onSearchError,
}: EmailFraudReportPageProps) {
  const queryResult = useReport(permalink);
  const zeroed = isZeroed(queryResult);

  const [tabState, setTabState] = useState<string>('email');

  const { hash, search } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={2} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }
  const report = new EmailFraudReport(queryResult?.data);

  const queryParams = new URLSearchParams(search);
  const fallbackEmail = queryParams.get('email') || '';

  const {
    address,
    fraudScore,
    isSuspect,
    isShared,
    isDisposable,
    isCatchAll,
    isFrequentComplainer,
    activity,
    isLeaked,
    firstSeen,
    deliverability,
    validityScore,
    isHoneypot,
    isRecentAbuse,
    domain,
    name,
    spamTrapScore,
  } = report.data;

  const {
    trust,
    isRiskyTld,
    isDmarcRecord,
    isSpfRecord,
    isDnsValid,
    mxRecords,
    suggestedDomain,
  } = domain || {};

  const lowerCaseEmailAddress = address?.toLowerCase() ?? fallbackEmail;

  if (!report) return <ReportLoading menuItems={2} />;

  if (report?.data?.isEmpty) {
    return <ReportNullState />;
  }

  return (
    <>
      <SearchFormsContainer>
        <Text data-cy="fraud-check-form-title" variant="h2" color="success">
          FraudScan
        </Text>
        <Text
          mb={(theme) => ({ xs: theme.spacing(1), md: theme.spacing(3) })}
          data-cy="fraud-check-form-subtitle"
          variant="body2"
        >
          {getFraudSearchSubtitleText(tabState)}
        </Text>
        <FraudSearchContainer
          excludeTabs={['ip', 'url']}
          initialTab={'email'}
          onSearchSuccess={onSearchSuccess}
          onSearchError={onSearchError}
          onSearchChange={setTabState}
        />
      </SearchFormsContainer>
      <ReportContainer
        sx={{ width: 'fit-content' }}
        container
        direction={'row'}
        columns={12}
      >
        <DetailsContainer my={3} width="100%">
          <Text
            data-cy="fraud-check-form-title"
            variant="h3"
            color="success"
            mb={1.5}
          >
            Email Verification
          </Text>
          <Box
            padding={1}
            mb={4}
            sx={(theme) => ({
              backgroundColor: alpha(theme.palette.success.dark, 0.04),
            })}
          >
            <Text variant="h5" component="p" fontWeight={700} m={0}>
              Results for: {lowerCaseEmailAddress}
            </Text>
          </Box>
        </DetailsContainer>
        <Grid item xs={12} mb={4}>
          <FraudEmailOverview
            fraudScore={fraudScore}
            isValid={true}
            isSuspect={isSuspect}
          />
        </Grid>

        <Divider sx={{ mt: 4 }} />
        <Grid item xs={12} mb={4}>
          <FraudEmailThreatDetection
            isRecentAbuse={isRecentAbuse}
            isRiskyTld={isRiskyTld}
            isHoneyPot={isHoneypot}
            spamTrapScore={spamTrapScore}
            domainTrust={trust}
          />
        </Grid>

        <Divider sx={{ mt: 4 }} />
        <Grid item xs={12}>
          <FraudEmailDetails
            isDMARC={isDmarcRecord}
            isSPF={isSpfRecord}
            isDnsValid={isDnsValid}
            sMTP={String(validityScore)}
            isGenericRole={isShared}
            mxRecords={mxRecords}
            deliverability={deliverability}
            userActivity={activity}
            isDisposable={isDisposable}
            typo={suggestedDomain}
            isCatchAll={isCatchAll}
            year={firstSeen?.parsed?.year}
            month={firstSeen?.parsed?.month}
            isFrequentComplainer={isFrequentComplainer}
            firstName={name?.parsed?.first}
            isLeaked={isLeaked}
          />
        </Grid>
      </ReportContainer>
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}
