import { Stack, Drawer, Button, styled } from '@ltvco/refresh-lib/theme';

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3, 2),
  overflow: 'scroll',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: 'calc(100% - 700px)',
  ...(!open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

export const StyledTopBar = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  zIndex: 3,
  height: '66px',
  padding: theme.spacing(1.75, 2, 0),
  background: theme.palette.background.paper,
  border: '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow:
    '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
}));

export const StyledDrawer = styled(Drawer)(() => ({
  width: 'calc(100% - 700px)',
  flexShrink: 0,
  overflowY: 'scroll',
  zIndex: 1,

  '& .MuiDrawer-paper': {
    width: 'calc(100% - 700px)',
    position: 'absolute',
  },
}));

export const MobileMapButton = styled(Button)(({ theme }) => ({
  zIndex: '2',
  position: 'fixed',
  bottom: '100px',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,

  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
}));
