import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import {
  Text,
  Grid,
  Box,
  Card,
  Link,
  Stack,
  CustomIcons,
} from '@ltvco/refresh-lib/theme';
import { checkPlanVariations } from 'utils/checkPlanVariations';
import { SearchHub } from '../SearchHub/SearchHub';
import { FeatureCard } from '@ltvco/refresh-lib/v2';
import { Identity, DarkWebScan, Monitoring, MemberPerks } from 'icons';
import {
  useAccount,
  PremiumDataModal,
  usePremiumDataModal,
  useReports,
} from '@ltvco/refresh-lib/v1';
import { PremiumPaymentSuccessModal } from '@ltvco/refresh-lib/payments';
import { DashboardRecentReports } from './components/DashboardRecentReports';
import { DashboardSuggestedReports } from './components/DashboardSuggestedReports';
import { MyClaimedReports } from 'components/ClaimedPropertiesCards/ClaimedPropertiesCards';
import { constants } from 'appConstants';
import { AppConstants } from '@ltvco/refresh-lib/ctx';
import { publish } from '@ltvco/refresh-lib';

export function DashboardPage() {
  useEffect(() => {
    document.title = 'Dashboard - NeighborWho';
  }, []);
  const {
    links: { propertyAdvertiseLink },
  } = useContext(AppConstants);
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();
  const { data: account } = useAccount(true);
  const { isModalOpen, closeModal, triggerModal } = usePremiumDataModal();
  const getClaimedReports = useReports('claimed');
  const { data: claimedReportsData } = getClaimedReports;
  const claimedPropertyReports = claimedReportsData?.reports.filter(
    (report) => report?.reportType === 'property_report'
  );
  const [searchType, setSearchType] = useState('');
  const [isInSingleReportVariation, setIsInSingleReportVariation] =
    useState(false);
  const [paymentShowModalNumber, setPaymentShowModalNumber] =
    useState<number>(0);
  const [isSuccessModaVisible, setIsSuccessModaVisible] =
    useState<boolean>(false);

  const openSuccessModal = () => {
    setIsSuccessModaVisible(true);
  };

  const closeModals = () => {
    setIsSuccessModaVisible(false);
    setPaymentShowModalNumber(0);
  };

  const onSuccessPaymentHandler = () => {
    closeModals();
    navigate('/dashboard');
  };

  useEffect(() => {
    if (!account) return;
    const planName =
      account.account.subscription_info.subscription_plan_unique_name;
    const { isInSingleReportVariation } = checkPlanVariations(planName);
    setIsInSingleReportVariation(isInSingleReportVariation);
  }, [account]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isFallFromCTAEmail = urlParams.get('premiumData');

    if (isInSingleReportVariation && isFallFromCTAEmail === 'true') {
      triggerModal(true, isInSingleReportVariation);
    }
  }, [isInSingleReportVariation]);

  const featureAction = (str: string) => {
    setSearchType(str);
    triggerModal(true, isInSingleReportVariation);
  };

  const features = [
    {
      title: 'Identity Hub',
      description:
        'Monitor your online identity to get notified if changes are detected',
      icon: <Identity />,
      action: () =>
        isInSingleReportVariation
          ? featureAction('identity hub')
          : navigate('/dashboard/identity-hub'),
    },
    {
      title: 'Search Background Reports',
      description: 'Find arrest records, contact information and more',
      icon: (
        <CustomIcons.SelfSearchBV
          sx={{ fontSize: '2.0rem', marginLeft: '0.24rem' }}
          primaryColor="#B2E8F3"
          secondaryColor="#6DD6EC"
        />
      ),
      action: () => publish('openPersonSearch'),
    },
    {
      title: 'Dark Web Scan',
      description: 'Check if your email was potentially leaked',
      icon: <DarkWebScan />,
      action: () =>
        isInSingleReportVariation
          ? featureAction('dark web')
          : navigate('/dashboard/dark-web'),
    },
    {
      title: 'Monitor your reports',
      description: 'Receive updates if we detect changes',
      icon: <Monitoring />,
      action: () => navigate('/dashboard/monitoring'),
    },
    {
      title: 'Member Perks',
      description:
        'Savings may be available through gift cards, discounts, and more',
      icon: <MemberPerks />,
      action: () => navigate('/dashboard/rewards'),
    },
  ];

  const bannerStyles = {
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: 2,
    boxShadow: 'none',
    padding: 1.5,
  };

  const LayerTechBanner = () => {
    return (
      <Grid item width={'100%'} alignItems={isMobile ? 'center' : 'unset'}>
        <Card variant="outlined" sx={bannerStyles}>
          <Grid
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            textAlign={isMobile ? 'center' : 'unset'}
            alignItems="center"
          >
            <img
              src={constants.images.imgLayerTech}
              width={'103px'}
              height={'88px'}
            />
            <Text
              sx={(theme) => ({
                padding: isMobile
                  ? theme.spacing(2.5, 0)
                  : theme.spacing(2.5, 5),
              })}
            >
              NeighborWho is powered by LayerTech™, technology designed to
              seamlessly integrate diverse data sources and offer a fuller
              picture of properties on and off-market.
            </Text>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const AdvertiseWithUsBanner = () => {
    return (
      <Stack
        width="100%"
        flexDirection="row"
        justifyContent="center"
        sx={bannerStyles}
      >
        Want to advertise with us?
        <Link href={propertyAdvertiseLink} marginLeft={0.5} target="_blank">
          Learn more
        </Link>
      </Stack>
    );
  };

  return (
    <Box display="flex" flexDirection="column" rowGap={2.5}>
      <SearchHub
        isInSingleReportVariation={isInSingleReportVariation}
        openUpgradeModal={triggerModal}
      />

      <DashboardSuggestedReports />
      <DashboardRecentReports />

      {Boolean(!claimedPropertyReports?.length) ? null : (
        <>
          <MyClaimedReports
            claimedReports={
              claimedPropertyReports ? claimedPropertyReports : []
            }
          />
        </>
      )}

      <Grid container direction="column">
        <Grid item>
          <Text variant="h3" mb={3} mt={2}>
            Explore New Features
          </Text>
        </Grid>
        <Grid container spacing={isMobile ? 1.5 : 2}>
          {features.map((feature) => (
            <Grid item xs={12} sm={6} md={4} key={feature.title}>
              <FeatureCard
                title={feature.title}
                description={feature.description}
                icon={feature.icon}
                action={feature.action}
                width={'100%'}
                height={120}
              />
            </Grid>
          ))}
          <LayerTechBanner />
        </Grid>
      </Grid>
      <AdvertiseWithUsBanner />
      {isInSingleReportVariation && (
        <PremiumDataModal
          isOpen={isModalOpen}
          onClose={closeModal}
          searchType={searchType}
          paymentShowModalNumber={paymentShowModalNumber}
          setPaymentShowModalNumber={setPaymentShowModalNumber}
          openSuccessModal={openSuccessModal}
          shouldFireGAUpsell
        />
      )}
      <PremiumPaymentSuccessModal
        open={isSuccessModaVisible}
        onClose={closeModals}
        onAccept={onSuccessPaymentHandler}
      />
    </Box>
  );
}
