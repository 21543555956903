import { useTheme } from '@ltvco/refresh-lib/theme';
import { Owner } from '@ltvco/refresh-lib/v1';
import { Share, Person2, Phone, Work, TaskAlt } from '@mui/icons-material';
import React from 'react';

export const getSocialNavLinkData = (
  owner: Owner,
  potentialOwnersLength: number
) => {
  return [
    {
      href: '#username-overview',
      sectionId: '#username-overview',
      text: 'Overview',
      reportItemsCount: null,
      hideReportItemsCount: true,
    },
    {
      href: '#potential-owners-section',
      sectionId: '#potential-owners-section',
      text: 'Potential Owners',
      reportItemsCount: potentialOwnersLength,
    },
    {
      href: '#possible-photos-section',
      sectionId: '#possible-photos-section',
      text: 'Possible Photos',
      reportItemsCount: null,
    },
    {
      href: '#phone-numbers-section',
      sectionId: '#phone-numbers-sectionn',
      text: 'Phone Numbers',
      reportItemsCount: owner.phones?.length || 0,
    },
    {
      href: '#email-section',
      sectionId: '#email-section',
      text: 'Emails',
      reportItemsCount: owner.emails?.length || 0,
    },
    {
      href: '#address-history-section',
      sectionId: '#address-history-section',
      text: 'Address History',
      reportItemsCount: owner.addresses?.length || 0,
    },
    {
      href: '#jobs-section',
      sectionId: '#jobs-section',
      text: 'Professional Background',
      reportItemsCount: owner.jobs?.length || 0,
    },
    {
      href: '#education-section',
      sectionId: '#education-section',
      text: 'Education',
      reportItemsCount: owner.educations?.length || 0,
    },
    {
      href: '#social-media-section',
      sectionId: '#social-media-section',
      text: 'Social & Websites',
      reportItemsCount: owner.profiles.length + owner.usernames.length || 0,
    },
  ];
};

export const getSocialNavLinkDataV2 = (
  owner: Owner,
  potentialOwnersLength: number
) => {
  const theme = useTheme();
  return [
    {
      href: '#username-overview',
      labelText: 'Overview',
      icons: [React.createElement(Share, { sx: { color: theme.palette.info.main, fontSize: '2.5rem' }})],
    },
    {
      labelText: 'Potential Owners',
      icons: [React.createElement(Person2, { sx: { color: theme.palette.info.main, fontSize: '1.4rem' }})],
      childItems: [
        {
          href: '#potential-owners-section',
          labelText: 'Potential Owners',
          reportItemsCount: potentialOwnersLength,
        },
        {
          href: '#possible-photos-section',
          labelText: 'Possible Photos',
          reportItemsCount: owner.images?.length || null,
        },
      ],
    },
    {
      labelText: 'Contact Information',
      icons: [React.createElement(Phone, { sx: { color: theme.palette.info.main, fontSize: '1.4rem' }})],
      childItems: [
        {
          href: '#phone-numbers-section',
          labelText: 'Phone Numbers',
          reportItemsCount: Math.max(owner.phones.length - 1, 0),
        },
        {
          href: '#email-section',
          labelText: 'Email Addresses',
          reportItemsCount: owner.emails.length,
        },
        {
          href: '#address-history-section',
          labelText: 'Address History',
          reportItemsCount: owner.addresses.length,
        },
        {
          href: '#social-media-section',
          sectionId: '#social-media-section',
          labelText: 'Social Media',
          reportItemsCount: owner.profiles.length + owner.usernames.length,
        },
      ],
    },
    {
      labelText: 'Jobs & Educations',
      icons: [React.createElement(Work, { sx: { color: theme.palette.info.main, fontSize: '1.4rem' }})],
      childItems: [
        {
          href: '#jobs-section',
          sectionId: '#jobs-section',
          labelText: 'Jobs',
          reportItemsCount: owner.jobs.length,
        },
        {
          href: '#education-section',
          sectionId: '#education-section',
          labelText: 'Education',
          reportItemsCount: owner.educations.length,
        },
      ],
    },
    {
      labelText: 'Next Steps',
      icons: [React.createElement(TaskAlt, { sx: { color: theme.palette.info.main, fontSize: '1.4rem' }})],
      childItems: [
        {
          href: '#Notes-section',
          labelText: 'Notes',
          hideReportItemsCount: true,
        },
        {
          href: '#related-reports-section',
          labelText: 'Related Reports',
          hideReportItemsCount: true,
        },
        {
          href: '#report-rating',
          labelText: 'Report Rating',
          hideReportItemsCount: true,
        },
      ],
    },
  ];
};
